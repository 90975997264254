import { Box, Container, Divider, Stack, Link } from "@mui/material";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const noHeaderFooter = ["/login", "/register", "/checkout", "/reset-password"];
  if (noHeaderFooter.includes(window.location.pathname)) {
    return null;
  }
  return (
    <footer className="bg-[#F5F7F7] py-6">
      <Container maxWidth="lg">
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Link color="inherit" href="/">
            {t("footer.home")}
          </Link>
          <Link color="inherit" href="https://audiohatdar.com/terms_and_conditions/">
            {t("footer.terms_and_conditions")}
          </Link>
          <Link color="inherit" href="https://audiohatdar.com/privacy-policy/">
            {t("footer.privacy_policy")}
          </Link>
          <Link color="inherit" href="https://audiohatdar.com/contact_us/">
            {t("footer.contact_us")}
          </Link>
          <Link color="inherit" href="https://audiohatdar.com/فريق-عمل-أوديوهات/">
            {t("footer.team_photos")}
          </Link>
          <Link color="inherit" href="https://audiohatdar.com/الأسئلة-المتكررة/">
            {t("footer.faq")}
          </Link>
        </Stack>
        <Box className="text-center border-t-1">{`Copyright ${new Date().getFullYear()} © Audiohatdar`}</Box>
      </Container>
    </footer>
  );
};

export default Footer;
