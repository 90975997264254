//This Table toolbar is used in Books table for admin
//-------------------------------------------------------------------------------//
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { styled, alpha } from "@mui/material/styles";
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from "@mui/material";
// component
import FormModal from "../../Form/FormModal";
import FilterForm from "../../filterForm/FilterForm";
import ColumnsMenu from "./ColumnsMenu";
import Iconify from "../../iconify";
//-------------------------------------------------------------------------------//
//-------------------------------------------------------------------------------//
const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 40,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));
//-------------------------------------------------------------------------------//
//-------------------------------------------------------------------------------//
const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 36,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
//-------------------------------------------------------------------------------//
//-------------------------------------------------------------------------------//
TableToolbar.propTypes = {
  numSelected: PropTypes.number,
  query: PropTypes.string,
  handleQuery: PropTypes.func,
};
//-------------------------------------------------------------------------------//
//-------------------------------------------------------------------------------//
export default function TableToolbar({
  numSelected,
  query,
  handleQuery,
  columnsList,
  tableId,
  handleAddClick,
  filter,
  setFilter,
  filterFormType,
}) {
  //-------------------------------------------------------------------------------//
  //-------------------------------------------------------------------------------//
  const [formModalOpen, toggleFormModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const skipAddingButton = ["admin.table.comments", "admin.table.logs", "user.table.logs", "admin.table.transactions"];
  const skipFilterButton = [
    "admin.table.serieses",
    "admin.table.comments",
    "admin.table.logs",
    "admin.table.authors",
    "admin.table.categories",
    "admin.table.plans",
    "admin.table.announcements",
    "user.table.logs",
  ];
  //-------------------------------------------------------------------------------//
  //-------------------------------------------------------------------------------//
  const getAddNewPath = (tableId) => {
    // remove the "admin.table." part
    const path = tableId.split(".").slice(2).join(".");
    return `/admin/${path}/add`;
  };
  //-------------------------------------------------------------------------------//
  //-------------------------------------------------------------------------------//
  return (
    <StyledRoot
      sx={{
        paddingLeft: "0 !important",
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {/* ---------------ٍSearch Field-------------------- */}
      {/* ----------------------------------------------- */}
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {t("select.selected", { count: numSelected })}
        </Typography>
      ) : (
        <StyledSearch
          value={query}
          onChange={handleQuery}
          placeholder={t("search")}
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: "text.disabled", width: 20, height: 20 }} />
            </InputAdornment>
          }
        ></StyledSearch>
      )}
      {/* ------------------Add button------------------- */}
      {/* ----------------------------------------------- */}
      <div className="flex items-center">
        {skipAddingButton.includes(tableId) ? null : (
          <Tooltip title={t(`${tableId}AddNew`)}>
            {handleAddClick ? (
              <IconButton onClick={handleAddClick}>
                <Iconify icon="carbon:add-filled" />
              </IconButton>
            ) : (
              <IconButton onClick={() => navigate(getAddNewPath(tableId))}>
                <Iconify icon="carbon:add-filled" />
              </IconButton>
            )}
          </Tooltip>
        )}
        {/* -----------------filter icon------------------- */}
        {/* ----------------------------------------------- */}
        {!skipFilterButton.includes(tableId) && (
          <Tooltip>
            <IconButton
              onClick={() => {
                toggleFormModal(true);
              }}
            >
              <Iconify icon="mdi:filter" />
            </IconButton>
          </Tooltip>
        )}
        {/* ---------------columns option------------------ */}
        {/* ----------------------------------------------- */}
        <ColumnsMenu columnsList={columnsList} tableId={tableId} icon={<Iconify icon="ph:text-columns-bold" />} />
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton>
              <Iconify icon="eva:trash-2-fill" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {/* ---------------------------------------------- */}
      {/* ---------------------------------------------- */}
      <FormModal
        open={formModalOpen}
        form={
          <FilterForm toggleFormModal={toggleFormModal} filter={filter} setFilter={setFilter} type={filterFormType} />
        }
      ></FormModal>
    </StyledRoot>
  );
}
