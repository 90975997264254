import { createSlice } from "@reduxjs/toolkit";
import { ImageUtils, PlanUtils } from "../../utils";

export const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      state.user.avatar = ImageUtils.getMixedObjLocation(state.user);
    },
    logout: (state) => {
      state.user = null;
      //if admin has switched into one user account
      localStorage.removeItem("admin");
      PlanUtils.clearCart();
    },
    updateIsPaidUser: (state, action) => {
      state.user.isPaidUser = action.payload.isPaidUser;
      state.user.activeUntil = action.payload.activeUntil;
    },
  },
});

export const { login, logout, updateIsPaidUser } = userSlice.actions;
export const selectUser = (state) => state.user.user;
export default userSlice.reducer;
