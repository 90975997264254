import { axios, requests } from "../../index";

const adminSettingsLoader = async () => {
  const { data } = await axios.get(`${requests.admin.settings}`);

  if (data && data.settings) {
    // return defaultSettings if settings is empty
    if (data.settings.length === 0) {
      return [];
    }
    return data.settings;
  }
  return [];
};

export default adminSettingsLoader;
