import { axios, requests } from "../../index";

const authorsLoader = async ({ params }) => {
    const { data } = await axios.get(`${requests.authors}/${params.id}/books`);
    if (data && data.books) {
        const { data: data2 } = await axios.get(requests.authors);
        return {
            allBooks: data.books,
            authors: data2.authors || [],
        };
    }
    return [];
};

export default authorsLoader;
