import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import { RootLayout, RootBooksLayout, AdminLayout, UserLayout } from "../layouts";
//----User Screens----//
//--------------------//
import {
  HomeScreen,
  LoginScreen,
  ProfileScreen,
  ResetPasswordScreen,
  BooksAll,
  BookDetails,
  CategoreyList,
  CategoryBooks,
  AuthorsList,
  AuthorBooks,
  SeriesesList,
  SeriesBooks,
  // UserFavorites,
  Checkout,
  VerifyPaytabs,
  VerifyFawry,
  // UserLogs,
  //PlansScreen,
  DashboardAppPage,
  //NotificationsPage,
  AccountPage,
  ChangePlan,
} from "../screens/UserScreens";
//----Admin Screens----//
//---------------------//
import {
  AdminAnnouncements,
  AdminAuthors,
  AdminBooks,
  AddBookForm,
  EditBookForm,
  AdminCategories,
  AdminComments,
  AdminReportedComments,
  AdminRepliesReported,
  AdminDeletedComments,
  AdminDeletedReplies,
  AdminDashboard,
  AdminSerieses,
  SerieForm,
  AdminUsers,
  AdminSupport,
  AdminTransactions,
  UserForm,
  AdminPlans,
  StatisticsScreen,
  SettingsScreen,
} from "../screens/AdminScreens";
//--------------------//
//--------------------//
import Page404 from "../screens/Page404";
import ErrorBoundary from "../screens/ErrorBoundary";
import Logout from "../screens/Logout";
import ProtectedRoute from "./ProtectedRoute";
import AdminRoute from "./AdminRoute";
//------Loaders------//
//-------------------//
import {
  categoriesLoader,
  bookLoader,
  booksLoader,
  categoryBooksLoader,
  authorsLoader,
  authorBooksLoader,
  seriesesLoader,
  serieBooksLoader,
  plansLoader,
  // userLogsLoader,
  // userFavoritesLoader,
  adminBooksLoader,
  adminAuthorsLoader,
  adminCategoriesLoader,
  adminAnnouncementsLoader,
  adminSeriesesLoader,
  adminBooksInfoLoader,
  adminBookListLoader,
  adminPlansLoader,
  adminSupportLoader,
  deletedCommentsLoader,
  deletedRepliesLoader,
  reportedCommentsLoader,
  reportedRepliesLoader,
  adminSettingsLoader,
  adminStatisticsLoader,
} from "../api";

//-----------------------------------------------------------------------------------//
//-----------------------------------------------------------------------------------//
const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path="/" element={<RootLayout />} errorElement={<ErrorBoundary />}>
      <Route index element={<HomeScreen />} />
      <Route path="books">
        <Route index element={<BooksAll />} loader={booksLoader} />
        <Route path=":id" element={<BookDetails />} loader={bookLoader} />
      </Route>
      <Route path="categories" element={<RootBooksLayout />}>
        <Route index element={<CategoreyList />} loader={categoriesLoader} />
        <Route path=":id/books" element={<CategoryBooks />} loader={categoryBooksLoader} />
      </Route>
      <Route path="authors" element={<RootBooksLayout />}>
        <Route index element={<AuthorsList />} loader={authorsLoader} />
        <Route path=":id/books" element={<AuthorBooks />} loader={authorBooksLoader} />
      </Route>
      <Route path="serieses" element={<RootBooksLayout />}>
        <Route index element={<SeriesesList />} loader={seriesesLoader} />
        <Route path=":id/books" element={<SeriesBooks />} loader={serieBooksLoader} />
      </Route>
      <Route
        path="user"
        element={
          <ProtectedRoute>
            <UserLayout />
          </ProtectedRoute>
        }>
        <Route index element={<DashboardAppPage />} />
        <Route path="profile" element={<ProfileScreen />} />
        {/* <Route path="notifications" element={<NotificationsPage />} /> */}
        <Route path="account" element={<AccountPage />} />
        <Route path="account/change-plan" element={<ChangePlan />} loader={plansLoader} />
        {/* <Route path="log" element={<UserLogs />} loader={userLogsLoader} /> */}
        {/* <Route path="favorites" element={<UserFavorites />} loader={userFavoritesLoader} /> */}
      </Route>
      <Route path="login" element={<LoginScreen />} />,
      <Route path="reset-password" element={<ResetPasswordScreen />} />,
      <Route path="register" element={<LoginScreen />} />,
      <Route path="checkout" element={<Checkout />} />,
      <Route path="verify/paytabs" element={<VerifyPaytabs />} />,
      <Route path="verify/fawry" element={<VerifyFawry />} />,
      <Route path="plans" element={<ChangePlan />} loader={plansLoader} />
    </Route>,

    <Route
      path="admin"
      element={
        <AdminRoute>
          <AdminLayout />
        </AdminRoute>
      }
      errorElement={<ErrorBoundary />}>
      <Route index element={<AdminDashboard />} />
      <Route path="notifications" element={<h3>Notifications</h3>} />
      <Route path="announcements">
        <Route index element={<AdminAnnouncements />} loader={adminAnnouncementsLoader} />
      </Route>
      <Route path="users">
        <Route index element={<AdminUsers />} />
        <Route path="edit" element={<UserForm />} />
      </Route>
      <Route path="support">
        <Route index element={<AdminSupport />} loader={adminSupportLoader} />
      </Route>
      <Route path="categories">
        <Route index element={<AdminCategories />} loader={adminCategoriesLoader} />
      </Route>
      <Route path="transactions">
        <Route index element={<AdminTransactions />} />
      </Route>
      <Route path="comments">
        <Route index element={<AdminComments />} />
        <Route path="reported-comments" element={<AdminReportedComments />} loader={reportedCommentsLoader} />
        <Route path="deleted-comments" element={<AdminDeletedComments />} loader={deletedCommentsLoader} />
        <Route path="reported-replies" element={<AdminRepliesReported />} loader={reportedRepliesLoader} />
        <Route path="deleted-replies" element={<AdminDeletedReplies />} loader={deletedRepliesLoader} />
      </Route>
      <Route path="authors">
        <Route index element={<AdminAuthors />} loader={adminAuthorsLoader} />
      </Route>
      <Route path="plans">
        <Route index element={<AdminPlans />} loader={adminPlansLoader} />
      </Route>
      <Route path="statistics">
        <Route index element={<StatisticsScreen />} loader={adminStatisticsLoader} />
      </Route>
      <Route path="serieses">
        <Route index element={<AdminSerieses />} loader={adminSeriesesLoader} />
        <Route path="add" element={<SerieForm />} loader={adminBookListLoader} />
        <Route path="edit" element={<SerieForm />} loader={adminBookListLoader} />
      </Route>
      <Route path="books">
        <Route index element={<AdminBooks />} loader={adminBooksLoader} />
        <Route path=":type/:id" element={<AdminBooks />} loader={adminBooksLoader} />
        <Route path="add" element={<AddBookForm />} loader={adminBooksInfoLoader} />
        <Route path="edit" element={<EditBookForm />} loader={adminBooksInfoLoader} />
      </Route>
      <Route path="settings">
        <Route index element={<SettingsScreen />} loader={adminSettingsLoader} />
      </Route>
    </Route>,
    <Route path="/logout" element={<Logout />} />,
    <Route path="*" element={<Page404 />} />,
  ]),
);
export default router;
