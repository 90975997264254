import { axios, requests } from "../../index";

const adminCategoriesLoader = async () => {
	const { data } = await axios.get(`${requests.admin.categories}`);
	if (data && data.categories) {
		return data.categories;
	}
	return null;
};

export default adminCategoriesLoader;
