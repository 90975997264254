import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logout } from "../app/features/userSlice";
import { UserUtils } from "../utils";
const Logout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.confirm(t("user.alerts.logout.confirm"))) {
      dispatch(logout());
      UserUtils.logout();
      navigate("/", { replace: true });
    } else {
      navigate(-1, { replace: true });
    }
  }, [dispatch, navigate, t]);

  return null;
};

export default Logout;
