import Iconify from "../components/iconify";
const tns = "admin.sidebar";
const EndPoints = [
  {
    title: "titles.user.dashboard",
    path: "/admin",
    icon: <Iconify icon="clarity:dashboard-solid" />,
  },
  {
    title: `${tns}.books`,
    path: "/admin/books",
    icon: <Iconify icon="emojione-monotone:books" />,
  },
  {
    title: `${tns}.authors`,
    path: "/admin/authors",
    icon: <Iconify icon="streamline:interface-edit-write-circle-change-circle-edit-modify-pencil-write-writing" />,
  },
  {
    title: `${tns}.categories`,
    path: "/admin/categories",
    icon: <Iconify icon="carbon:categories" />,
  },
  {
    title: `${tns}.comments`,
    path: "/admin/comments",
    icon: <Iconify icon="mdi:comments" />,
  },
  {
    title: `${tns}.serieses`,
    path: "/admin/serieses",
    icon: <Iconify icon="solar:library-outline" />,
  },
  {
    title: `${tns}.users`,
    path: "/admin/users",
    icon: <Iconify icon="clarity:users-line" />,
  },
  {
    title: `${tns}.support`,
    path: "/admin/support",
    icon: <Iconify icon="mdi:support" />,
  },
  {
    title: `${tns}.announcements`,
    path: "/admin/announcements",
    icon: <Iconify icon="ri:advertisement-fill" />,
  },
  {
    title: `${tns}.plans`,
    path: "/admin/plans",
    icon: <Iconify icon="ic:sharp-card-membership" />,
  },
  {
    title: `${tns}.transactions`,
    path: "/admin/transactions",
    icon: <Iconify icon="tdesign:money" />,
  },
  { title: `${tns}.statistics`, path: "/admin/statistics", icon: <Iconify icon="bx:bxs-bar-chart-alt-2" /> },
  {
    title: `${tns}.settings`,
    path: "/admin/settings",
    icon: <Iconify icon="ic:sharp-settings-suggest" />,
  },
  {
    devider: true,
    title: "devider-0",
  },
  {
    title: "Audiohat",
    path: "/",
    icon: <Iconify icon="el:website-alt" />,
  },
  {
    devider: true,
    title: "devider-1",
  },
  {
    title: "user.logout",
    path: "/logout",
    icon: <Iconify icon="ep:turn-off" />,
  },
];

export default EndPoints;
