import { Box, Rating } from "@mui/material";
import { IntegerUtil } from "../../utils";

const BookRatingView = ({ book, boxClass = "" }) => {
    if (!book.voters || !book.rate || book.voters <= 0 || book.rate <= 0) return null;

    const rating = IntegerUtil.bookRate(book);
    return (
        <Box className={`rating-view-box ${boxClass}`}>
            <Rating
                name="simple-controlled"
                value={rating}
                readOnly
                size="small"
                sx={{
                    fontSize: "14px",
                    marginTop: "4px",
                    "& .MuiSvgIcon-root": {
                        stroke: "#000",
                    },
                }}
            />
            <div className="pt-2 mr-2">{`${Math.round(rating * 10) / 10} (${book.voters} تقييم)`}</div>
        </Box>
    );
};

export default BookRatingView;
