import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, ImageList, ImageListItem } from "@mui/material";

import { motion } from "framer-motion";
import BookRatingView from "./BookRatingView";
import BookDuration from "./BookDuration";
import BookSubtitle from "./BookSubtitle";
import BookReleaseDate from "./BookReleaseDate";
import AuthorNameWithIcon from "./AuthorNameWithIcon";
import FreeBookBadge from "./FreeBookBadge";

import { ImageUtils } from "../../utils";

/**
 * JSX component to show books grid just in books page , it's different from GridComponent because it has more details
 * @param {Array} books
 * @returns JSX.Element
 */
const BooksGrid = ({ books }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    // cols depend on screen size 2 for xs and 3 for sm and above

    const screenWidth = window.innerWidth;
    let cols = 4;
    if (screenWidth <= 414) {
        cols = 1;
    } else if (screenWidth > 414 && screenWidth <= 768) {
        cols = 2;
    } else if (screenWidth > 768 && screenWidth <= 1024) {
        cols = 3;
    }

    const handelOnClick = (book) => {
        if (book.isSerie) {
            navigate(`/serieses/${book._id}/books`);
        } else {
            navigate(`/books/${book._id}`);
        }
    };
    function translateTitle(book) {
        const translatedTitle = book.isSerie
            ? t("serie", { title: book.title }) + " - " + t("books_count", { count: book.numberOfBooks }) + " - "
            : book.title;
        return translatedTitle;
    }

    const ItemImage = ({ book }) => {
        return (
            <div className="relative">
                <img
                    onClick={() => handelOnClick(book)}
                    className="h-[250px] w-[100%] object-cover object-center hover:opacity-80 cursor-pointer rounded-md"
                    src={ImageUtils.getMixedObjLocation(book.thumbnail)}
                    alt={book.title}
                    loading="lazy"
                />
                {book.isFree && <FreeBookBadge size="large" />}
            </div>
        );
    };

    return (
        <ImageList
            sx={{
                transform: "translateZ(0)",
            }}
            gap={10}
            cols={12}>
            {books.map((book, index) => {
                return (
                    <ImageListItem key={book._id} cols={12 / cols}>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: 1,
                                transition: { delay: (index + 1) * 0.2, type: "spring" },
                            }}>
                            <ItemImage book={book} />
                            <div className="absolute top-0 left-0 right-0 bg-stone-950/75 text-right text-slate-100">
                                <BookRatingView book={book} />
                            </div>
                            <Box
                                sx={{
                                    color: "#f1f1f1",
                                    backgroundColor: "#111",
                                    textAlign: "center",
                                    fontSize: "1.2rem",
                                    borderEndEndRadius: "0.5rem",
                                    borderEndStartRadius: "0.5rem",
                                    paddingY: "0.5rem",
                                    pointerEvents: "none",
                                    marginTop: "-5px",
                                    zIndex: "0",
                                }}>
                                <div className="underline">{translateTitle(book)}</div>
                                <BookSubtitle book={book} />
                                <AuthorNameWithIcon book={book} translate />
                                <div>
                                    <BookDuration book={book} />
                                    <BookReleaseDate book={book} spanClassNames="mr-3" />
                                </div>
                            </Box>
                        </motion.div>
                    </ImageListItem>
                );
            })}
        </ImageList>
    );
};

export default BooksGrid;
