import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";

const BookSubtitle = ({ book }) => {
    return (
        <>
            <SubdirectoryArrowLeftIcon fontSize="12" className="ml-2" />
            {book.subtitle}
        </>
    );
};

export default BookSubtitle;
