import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";

import { SpeedDial, SpeedDialIcon, SpeedDialAction } from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

const actions = [
	{ icon: <ArrowBackIosNewIcon />, name: <Trans>Go Back</Trans>, action: "goBack" },
	{ icon: <DeleteIcon />, name: <Trans>Delete</Trans>, action: "delete" },
	{ icon: <SaveIcon />, name: <Trans>Save</Trans>, action: "save" },
];

export default function FormSpeedDial({ handleDelete, formik }) {
	const navigate = useNavigate();
	return (
		<div className="relative h-16 w-[100%]">
			<SpeedDial
				ariaLabel="Form actions"
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					zIndex: 1000,
					"& .MuiSpeedDial-fab": {
						backgroundColor: "rgba(0, 0, 0, 0.5)",
					},
				}}
				direction="left"
				icon={<SpeedDialIcon />}
			>
				{actions.map((action) => (
					<SpeedDialAction
						key={action.name}
						icon={action.icon}
						tooltipTitle={action.name}
						onClick={() => {
							switch (action.action) {
								case "goBack":
									navigate(-1);
									break;
								case "delete":
									handleDelete();
									break;
								case "save":
									if (formik.isValid) {
										formik.submitForm();
									} else {
										console.log("invalid form");
									}
									break;
								default:
									break;
							}
						}}
					/>
				))}
			</SpeedDial>
		</div>
	);
}
