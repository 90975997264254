import { useNavigate } from "react-router-dom";

import CreateTwoToneIcon from "@mui/icons-material/CreateTwoTone";
import { useTranslation } from "react-i18next";
/**
 * JSX component to show author name with icon
 * @param {Object} book
 * @param {Boolean} translate - if true, translate the author name to the current language default is false
 * @returns JSX.Element
 */
const AuthorNameWithIcon = ({ book, translate = false }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (!book.author) return null;
    const translateAuthor = t("by_author", { name: book.author?.name || "" });
    return (
        <div onClick={() => navigate(`/authors/${book.author._id}`)}>
            <CreateTwoToneIcon fontSize="12" className="ml-2" />
            {translate === true ? translateAuthor : book.author?.name}
        </div>
    );
};

export default AuthorNameWithIcon;
