import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

TableHeader.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  columns: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
};

export default function TableHeader({
  order,
  orderBy,
  rowCount,
  columns,
  numSelected,
  onRequestSort,
  onSelectAllClick,
}) {
  const { t } = useTranslation();
  const params = useParams();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => {
          if (
            headCell.id === "orderInCategory" &&
            params.type !== "categories"
          ) {
            return null;
          }
          return (
            <TableCell
              style={{ whiteSpace: "nowrap" }}
              key={headCell.id}
              align={headCell.alignRight ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
              width={headCell.width}
            >
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={
                  headCell.noneSortable === true
                    ? null
                    : createSortHandler(headCell.id)
                }
                sx={{
                  cursor: headCell.noneSortable ? "default" : "pointer",
                  "&:hover": {
                    cursor: headCell.noneSortable ? "default" : "pointer",
                  },
                }}
              >
                {t(`table.adminColumns.${headCell.id}`)}
                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
        {onSelectAllClick && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}
