export default class IntegerUtil {
    static durationHHMMSS(duration) {
        let sec_num = parseInt(duration, 10); // don't forget the second param
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - hours * 3600) / 60);

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }

        if (hours > 0) {
            return `${hours} ساعة و${minutes} دقيقة`;
        }
        return `${minutes} دقيقة`;
    }
    static duration(duration) {
        let sec_num = parseInt(duration, 10); // don't forget the second param
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - hours * 3600) / 60);
        let seconds = sec_num - hours * 3600 - minutes * 60;

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }

        if (hours > 0) {
            return `${hours}:${minutes}:${seconds}`;
        }
        return `${minutes}:${seconds}`;
    }

    static bookRate(book) {
        const rate = book.rate ? book.rate : 0;
        const voters = book.voters ? book.voters : 0;
        return voters > 0 ? rate / voters : 0;
    }

    static bookPositionFormat(position) {
        let sec_num = parseInt(position, 10); // don't forget the second param
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - hours * 3600) / 60);
        let seconds = sec_num - hours * 3600 - minutes * 60;

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }

        if (hours > 0) {
            return `${hours}:${minutes}:${seconds}`;
        }
        return `${minutes}:${seconds}`;
    }

    static bookProgress(position = 0, duration = 0) {
        return position / duration;
    }
}
