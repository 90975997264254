import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
  Tooltip,
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import {
  selectAdminHiddenCols,
  updateHiddenCols,
} from "../../../app/features/adminHiddenColsSlice";

const ColumnsMenu = ({ columnsList, tableId, icon }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hiddenCols, setHiddenCols] = useState([]);

  const open = Boolean(anchorEl);
  const adminColumns = useSelector(selectAdminHiddenCols);
  const dispatch = useDispatch();

  useEffect(() => {
    // bail if no tableId or no columnList
    if (!tableId || !columnsList || !adminColumns[tableId]) return;
    // add the columns to the adminColumns if they don't exist
    setHiddenCols([...adminColumns[tableId]]);
  }, [tableId, adminColumns, columnsList]);

  if (!tableId || !columnsList) return null;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectItem = (column) => {
    if (column) {
      const cols = [...hiddenCols];
      const index = cols.indexOf(column.id);
      if (index > -1) {
        cols.splice(index, 1);
      } else {
        cols.push(column.id);
      }
      setHiddenCols(cols);
      dispatch(updateHiddenCols({ tableId, hiddenCols: cols }));
    }
  };

  return (
    <>
      <Tooltip title={t("table.columns options")}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {columnsList.map((column) => (
          <MenuItem key={column.id} onClick={() => handleSelectItem(column)}>
            <ListItemIcon>
              {!hiddenCols.includes(column.id) ? (
                <Check fontSize="small" />
              ) : null}
            </ListItemIcon>
            {t(`table.adminColumns.${column.id}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ColumnsMenu;
