import { axios, requests } from "../../index";

const adminAuthorsLoader = async () => {
	const { data } = await axios.get(`${requests.admin.authors}`);
	if (data && data.authors) {
		return data.authors;
	}
	return null;
};

export default adminAuthorsLoader;
