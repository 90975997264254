import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice";
import adminHiddenColsReducer from "./features/adminHiddenColsSlice";
import adminTableOptionsReducer from "./features/adminTableOptionsSlice";

const store = configureStore({
    reducer: {
        user: userReducer,
        adminHiddenCols: adminHiddenColsReducer,
        adminTableOptions: adminTableOptionsReducer,
    },
    preloadedState: loadFromLocalStorage(),
});

function saveToLocalStorage(state) {
    try {
        const serialState = JSON.stringify(state);
        localStorage.setItem("appState", serialState);
    } catch (e) {
        console.warn(e);
    }
}

function loadFromLocalStorage() {
    try {
        const serialisedState = localStorage.getItem("appState");
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState);
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}

store.subscribe(() => saveToLocalStorage(store.getState()));

export { store };
