class BreakpointsUtils {
    static getBreakpoints(isCats = false) {
        const breakpoints = {
            320: {
                slidesPerView: isCats ? 1 : 2,
                spaceBetween: 10,
            },
            480: {
                slidesPerView: isCats ? 1 : 3,
                spaceBetween: 10,
            },
            640: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 5,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 6,
                spaceBetween: 10,
            },
            1280: {
                slidesPerView: 6,
                spaceBetween: 10,
            },
            1536: {
                slidesPerView: 6,
                spaceBetween: 10,
            },
            1920: {
                slidesPerView: 6,
                spaceBetween: 10,
            },
        };
        return breakpoints;
    }

    static getSlidesPerGroup(isCats = false) {
        const screenWidth = window.innerWidth;

        const breakPoints = BreakpointsUtils.getBreakpoints(isCats);
        const breakPointsKeys = Object.keys(breakPoints);
        const closestBreakPoint = breakPointsKeys.reduce((prev, curr) => {
            return Math.abs(curr - screenWidth) < Math.abs(prev - screenWidth) ? curr : prev;
        });
        return breakPoints[closestBreakPoint]?.slidesPerView || 1;
    }
}

export default BreakpointsUtils;
