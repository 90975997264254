// ----------------------------------------------------------------------

export default function Typography(theme) {
    return {
        MuiTypography: {
            styleOverrides: {
                paragraph: {
                    marginBottom: theme.spacing(2),
                },
                gutterBottom: {
                    marginBottom: theme.spacing(1),
                },
            },
            variants: [
                {
                    props: { variant: "time-small" },
                    style: {
                        fontSize: "0.75rem",
                        backgroundColor: theme.palette.slatecolor[700],
                        color: theme.palette.slatecolor[50],
                        padding: theme.spacing(0.5, 1),
                        borderRadius: theme.shape.borderRadius / 2,
                        fontWeight: 900,
                    },
                },
            ],
        },
    };
}
