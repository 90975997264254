import { createSlice } from "@reduxjs/toolkit";

const adminTableOptionsSlice = createSlice({
    name: "adminTableOptions",
    initialState: {
        searchIn: {
            "admin.table.users": "",
            "admin.table.comments": "",
        },
    },
    reducers: {
        updateSearchIn: (state, action) => {
            state.searchIn[action.payload.tableId] = action.payload.searchFor;
        },
    },
});

export const { updateSearchIn } = adminTableOptionsSlice.actions;
export const selectAdminTableOptions = (state) => state.adminTableOptions;
export default adminTableOptionsSlice.reducer;
