import "../assets/css/FlatButton.css";
const FlatButton = ({ children, color, disabled, handleClick }) => {
    return (
        <button className={`flat-button ${color} mx-2`} disabled={disabled} onClick={handleClick}>
            {children}
        </button>
    );
};

export default FlatButton;
