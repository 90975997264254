import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { IntegerUtil, ImageUtils } from "../utils";
import BookThumbnail from "./Book/BookThumbnail";

const PlayerButton = ({ item }) => {
  const { t } = useTranslation();

  const [isplaying, setisplaying] = useState(false);
  const [startPlaying, setStartPlaying] = useState(false);

  const audioElem = useRef();
  const playingLabelRef = useRef();

  const { soundFile, isFree } = item;
  let fileUrl = soundFile.location;

  const mimetype = soundFile.mimetype;
  useEffect(() => {
    if (isplaying) {
      audioElem.current.play();
    } else {
      audioElem.current.pause();
    }
  }, [startPlaying, isplaying]);

  // bail if no url or empty string
  if (!fileUrl || fileUrl === "") {
    return null;
  }
  // check if url includes http or https and add it if not
  if (!fileUrl.includes("http")) {
    fileUrl = `https://${fileUrl}`;
  }

  const onPlaying = () => {
    // bail if no audio element or label element
    if (!audioElem.current) {
      return;
    }

    const duration = audioElem.current.duration;
    const ct = audioElem.current.currentTime;
    //const progress = (ct / duration) * 100;
    if (playingLabelRef.current) {
      playingLabelRef.current.innerHTML = `${IntegerUtil.duration(
        duration - ct
      )}`;
    }
  };

  const btnClasses =
    "text-slate-50 rounded-md px-4 py-2 bg-slate-900 hover:bg-slate-700";
  const PlayingState = () => {
    return (
      <>
        <span>
          <PauseIcon />
        </span>
        <span ref={playingLabelRef}>{t("stop")}</span>
      </>
    );
  };
  const PausedState = () => {
    // const duration = audioElem.current.duration;
    const ct = audioElem.current.currentTime;

    return (
      <>
        <span>
          <PlayArrowIcon />
        </span>
        <span ref={playingLabelRef}>{`${IntegerUtil.duration(ct)}`}</span>
      </>
    );
  };

  const IdleState = () => {
    return (
      <>
        <span>
          <PlayArrowIcon />
        </span>
        <span>{isFree ? t("play") : t("play_sample")}</span>
      </>
    );
  };

  if (mimetype.includes("video")) {
    return (
      <div className="p-0  relative">
        <video
          controls={true}
          ref={audioElem}
          src={fileUrl}
          onTimeUpdate={onPlaying}
          poster={ImageUtils.getMixedObjLocation(item.thumbnail)}
        />
      </div>
    );
  }

  return (
    <>
      <BookThumbnail item={item} />
      <div className="text-center mt-4">
        <audio ref={audioElem} src={fileUrl} onTimeUpdate={onPlaying} />
        <button
          className={btnClasses}
          onClick={() => {
            if (!startPlaying) {
              setStartPlaying(true);
              setisplaying(true);
            } else {
              setisplaying(!isplaying);
            }
          }}
        >
          {!startPlaying && <IdleState />}
          {startPlaying && isplaying && <PlayingState />}
          {startPlaying && !isplaying && <PausedState />}
        </button>
      </div>
    </>
  );
};

export default PlayerButton;
