import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import { IntegerUtil } from "../../utils";

const BookDuration = ({ book }) => {
  return (
    <span>
      <AccessTimeTwoToneIcon fontSize="12" className="ml-2" />
      {IntegerUtil.durationHHMMSS(book.soundFile?.duration || book.freeAudioFile?.duration || 0)}
    </span>
  );
};

export default BookDuration;
