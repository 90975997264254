import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { UserUtils } from "../../utils";

const StyledParagraph = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.secondary,
	fontSize: "0.9rem",
	marginLeft: theme.spacing(1),
}));

const AccountSubscriptionInfo = ({ user }) => {
	const { t } = useTranslation();
	// convert activeUntil to moment object
	const activeUntil = UserUtils.getActiveUntil(user);

	return (
		<>
			{activeUntil ? (
				<StyledParagraph>
					{activeUntil < Date.now() ? (
						<span className="text-red-600 underline">
							{t("user.expiredSubscriptionIn", { date: activeUntil.calendar() })}
						</span>
					) : (
						<span className="text-lime-600 underline">
							{t("user.activeSubscriptionUntil", { date: activeUntil.calendar() })}
						</span>
					)}
				</StyledParagraph>
			) : (
				<StyledParagraph>{t("user.activeUntil", { date: "No subscription" })}</StyledParagraph>
			)}
		</>
	);
};

export default AccountSubscriptionInfo;
