import { axios, requests } from "../../index";

const deletedRepliesLoader = async () => {
  const { data } = await axios.get(`${requests.admin.replies}/deleted`);
  if (data && data.replies) {
    return data.replies;
  }
  return [];
};

export default deletedRepliesLoader;
