import { axios, requests } from "../../index";

const adminAnnouncementsLoader = async () => {
	const { data } = await axios.get(`${requests.admin.announcements}`);
	if (data && data.announcements) {
		return data.announcements;
	}
	return null;
};

export default adminAnnouncementsLoader;
