import { axios, requests } from "../../index";

const categoriesLoader = async () => {
    const { data } = await axios.get(`${requests.home}/categories`);
    if (data && data.items) {
        return data.items;
    }
    return [];
};

export default categoriesLoader;
