import ReactLoading from "react-loading";

const Loading = ({ title }) => {
    return (
        <div className="fixed top-0 bottom-0 left-0 right-0 bg-stone-950/70 z-[99999]">
            <div className="flex flex-col items-center justify-center h-full">
                <ReactLoading type="spin" color="#fff" />
                {title && <h3 className="text-white mt-2 text-3xl">{title}</h3>}
            </div>
        </div>
    );
};

export default Loading;
