import {
    Box,
    Drawer,
    List,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    IconButton,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { useTranslation } from "react-i18next";

import UserSidebarItems from "../app/userSideBarItems";

const RootSidebar = ({ isOpen = false, handelClose, onItem }) => {
    const { t } = useTranslation();

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            closeAfterTransition={true}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
                onBackdropClick: handelClose,
            }}
            SlideProps={{
                timeout: 200,
                direction: "left",
            }}>
            <div className="text-left">
                <IconButton aria-label="Close sidebar" onClick={handelClose}>
                    <ChevronRightIcon />
                </IconButton>
            </div>
            <Box sx={{ width: 200 }} role="presentation" onKeyDown={handelClose}>
                <Divider />
                <List>
                    {UserSidebarItems.map((item, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton onClick={() => onItem(item)} dense divider>
                                <ListItemIcon sx={{ minWidth: 35 }}>{item.icon}</ListItemIcon>
                                <ListItemText primary={t(item.name)} disableTypography className="text-right" />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    );
};

export default RootSidebar;
