import { axios, requests } from "../../index";

const deletedCommentsLoader = async () => {
  const { data } = await axios.get(`${requests.admin.comments}/deleted`);
  if (data && data.comments) {
    return data.comments;
  }
  return null;
};

export default deletedCommentsLoader;
