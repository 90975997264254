import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import moment from "moment";

const StyledParagraph = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "0.9rem",
  marginLeft: theme.spacing(1),
}));

const SubscriptionInfo = ({ user }) => {
  const { t } = useTranslation();
  // convert activeUntil to moment object
  const activeUntil = user.activeUntil ? moment(user.activeUntil) : null;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <StyledParagraph>{t("user.createdAt", { date: moment(user.createdAt).fromNow() })}</StyledParagraph>
        {activeUntil ? (
          <StyledParagraph>
            {activeUntil < Date.now() ? (
              <>
                <span className="text-red-600 underline">
                  {t("user.expiredSubscriptionIn", { date: activeUntil.calendar() })}
                </span>
                <span className="text-amber-600 mr-2">
                  {t("user.lastSubscripedPlan", {
                    plan: user.plan ? user.plan.title : t("user.unknownPlanName"),
                  })}
                </span>
              </>
            ) : (
              <>
                <span className="text-lime-600 underline">
                  {t("user.activeSubscriptionUntil", { date: activeUntil.calendar() })}
                </span>
                <span className="text-amber-600 mr-2">
                  {t("user.activePlan", {
                    plan: user.plan ? user.plan.title : t("user.unknownPlanName"),
                  })}
                </span>
              </>
            )}
          </StyledParagraph>
        ) : (
          <StyledParagraph>غير مشترك</StyledParagraph>
        )}
      </Box>
    </>
  );
};

export default SubscriptionInfo;
