import { axios, requests } from "../../index";

const authorsLoader = async () => {
    const { data } = await axios.get(requests.authors);
    if (data && data.authors) {
        return data.authors;
    }
    return [];
};

export default authorsLoader;
