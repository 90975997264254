import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function FormCheckbox(props) {
    return (
        <div className="h-[100%] flex">
            <FormControlLabel
                control={<Checkbox />}
                sx={{ textDecoration: props.checked ? "none" : "line-through", "& .MuiSvgIcon-root": { fontSize: 28 } }}
                {...props}
            />
        </div>
    );
}
