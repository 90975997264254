// ----------------------------------------------------------------------

export default function Button(theme) {
    return {
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                startIcon: {
                    marginLeft: theme.spacing(1),
                    marginRight: theme.spacing(-1),
                },
                contained: {
                    boxShadow: "none",
                    "&:hover": {
                        boxShadow: "none",
                    },
                },
                containedSuccess: {
                    backgroundColor: "#4caf50 !important",
                    "&:hover": {
                        backgroundColor: "#388e3c !important",
                        color: "#ecfccb",
                    },
                },
                containedError: {
                    backgroundColor: "#f44336 !important",
                    "&:hover": {
                        backgroundColor: "#d32f2f !important",
                        color: "#fecaca",
                    },
                },
                containedWarning: {
                    backgroundColor: "#ff9800 !important",
                    "&:hover": {
                        backgroundColor: "#f57c00 !important",
                        color: "#fed7aa",
                    },
                },
            },
            variants: [
                {
                    props: { variant: "black-and-white" },
                    style: {
                        color: theme.palette.button.color,
                        backgroundColor: "#111 !important",
                        "&:hover": {
                            backgroundColor: "#e2e8f0 !important",
                            color: "#111 !important",
                        },
                    },
                },
            ],
        },
    };
}
