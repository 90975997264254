import { useTranslation } from "react-i18next";
import { UserUtils } from "../../utils";
const NextBillingInfo = ({ user }) => {
	const { t } = useTranslation();
	const activeUntil = UserUtils.getActiveUntil(user);
	if (!activeUntil || activeUntil === "") return null;

	return (
		<span className="text-xs block text-au-gray">
			{activeUntil > Date.now() ? (
				<>
					<span>{t("user.accountPage.Your next billing date is")}:</span>
					<span className="mr-2">{activeUntil.format("LL")}</span>
				</>
			) : (
				<span className="mr-2 text-red-600">{t("user.accountPage.The plan will not renew automatically")}</span>
			)}
		</span>
	);
};

export default NextBillingInfo;
