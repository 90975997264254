import { axios, requests } from "../../index";

const adminBookListLoader = async () => {
    const { data } = await axios.get(`${requests.admin.books}/forList`);
    if (data && data.books) {
        return data.books;
    }
    return [];
};

export default adminBookListLoader;
