import { createSlice } from "@reduxjs/toolkit";

export const adminHiddenColsSlice = createSlice({
	name: "adminHiddenCols",
	initialState: {
		"admin.table.books": [],
		"admin.table.authors": [],
		"admin.table.categories": [],
		"admin.table.users": [],
		"admin.table.comments": [],
		"admin.table.serieses": [],
		"admin.table.announcements": [],
		"admin.table.plans": [],
	},
	reducers: {
		updateHiddenCols: (state, action) => {
			state[action.payload.tableId] = action.payload.hiddenCols;
		},
	},
});

export const { updateHiddenCols } = adminHiddenColsSlice.actions;
export const selectAdminHiddenCols = (state) => state.adminHiddenCols;
export default adminHiddenColsSlice.reducer;
