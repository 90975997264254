import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

const SubscripeNowButton = ({ path, fullWidth = true }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const lines = t("subscription_benefits_short", { returnObjects: true });

    return (
        <Button
            variant="contained"
            color="success"
            className="subscripe-now-button"
            fullWidth={fullWidth}
            sx={{
                opacity: 0.9,
                backgroundColor: "#111 !important",
                boxShadow: "none !important",
                borderColor: "#fff !important",
                borderWidth: "1px !important",
                borderStyle: "solid !important",
                fontWeight: "bold !important",
                color: "#fff",
                "&:hover": {
                    backgroundColor: "#229A16 !important",
                    color: "#fff",
                },
            }}
            onClick={() => navigate(path)}>
            {lines.subscribe_button}
        </Button>
    );
};

export default SubscripeNowButton;
