import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    tableCellClasses,
    Toolbar,
    Tooltip,
    IconButton,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Iconify from "../iconify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 40,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1, 0, 3),
}));

const maxWidthStyle = {
    maxWidth: "640px",
    margin: "0 auto",
};

export default function SortableTable({
    rows,
    labelKey,
    title,
    addButtonTitle,
    handleAddClick,
    handleDrag,
    handleDrop,
    handleRowDelete,
}) {
    if (!rows) return null;

    return (
        <>
            <TableContainer component={Paper}>
                <StyledRoot
                    sx={{
                        paddingLeft: "0 !important",
                        ...maxWidthStyle,
                    }}>
                    <Typography variant="h6">{title}</Typography>

                    <div className="flex items-center">
                        <Tooltip title={addButtonTitle}>
                            <IconButton onClick={handleAddClick}>
                                <Iconify icon="carbon:add-filled" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </StyledRoot>
                <Table size="small" sx={{ ...maxWidthStyle }}>
                    <TableBody>
                        {rows.map((row, index) => (
                            <StyledTableRow
                                key={row._id}
                                sx={{ cursor: "grab", "&:last-child td, &:last-child th": { border: 0 } }}
                                draggable
                                onDragStart={(e) => handleDrag(e, row)}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleDrop(e, row)}>
                                <StyledTableCell width="40px">{index + 1}</StyledTableCell>
                                <StyledTableCell>{row[labelKey]}</StyledTableCell>
                                <StyledTableCell
                                    width="10px"
                                    sx={{
                                        padding: "0 !important",
                                        opacity: 0.5,
                                    }}>
                                    <IconButton onClick={() => handleRowDelete(row)}>
                                        <Iconify icon="typcn:delete-outline" sx={{ color: "red !important" }} />
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
