import { Badge } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";

const LikesBadge = ({ count = 0 }) => {
    if (count <= 0) return null;

    return (
        <Badge
            badgeContent={count}
            sx={{
                marginRight: "10px",
                "& .MuiBadge-badge": {
                    right: 0,
                    top: 13,
                    border: `2px solid ${"white"}`,
                    padding: "0 4px",
                    backgroundColor: "#4d7c0f",
                    color: "#fff",
                },
            }}>
            <FavoriteIcon
                sx={{
                    color: "#4d7c0f",
                    fontSize: "1.5rem",
                }}
            />
        </Badge>
    );
};

export default LikesBadge;
