import { axios, requests } from "../../index";

const bookLoader = async ({ params }) => {
    const { data } = await axios.get(`${requests.fetchBooks}/${params.id}`);

    if (data && data.book) {
        return data.book;
    }
    return null;
};

export default bookLoader;
