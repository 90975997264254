import { useTranslation } from "react-i18next";
import { UserUtils } from "../../utils";
import SubscripeNowButton from "./SubscripeNowButton";

const SubscripePrompt = ({ wrapperClass, listClass, path }) => {
    const { t } = useTranslation();
    const lines = t("subscription_benefits_short", { returnObjects: true });
    const user = UserUtils.getUser();
    if (user && (user.paidUser === true || user.isAdmin)) return null;

    return (
        <div className={`subscription-benefits ${wrapperClass}`}>
            <ul className={`list-disc list-inside font-extrabold ${listClass}`}>
                {["0", "1", "2", "3"].map((item, index) => (
                    <li key={index}>{lines[`line${item}`]}.</li>
                ))}
            </ul>
            <div className="text-center mt-4">
                <SubscripeNowButton path={path} />
            </div>
        </div>
    );
};

export default SubscripePrompt;
