import { IconButton } from "@mui/material";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ReportIcon from "@mui/icons-material/Report";

const LikeDislikeReport = ({ user, item, author, onLike, onDislike, onReport }) => {
    if (!user || !author || user._id === author._id || !item) {
        return null;
    }

    const userDidLiked = item && item.likes ? item.likes.includes(user._id) : false;
    const userDidDisliked = item && item.disLikes ? item.disLikes.includes(user._id) : false;

    const likeIconClass = userDidLiked
        ? "bg-green-700 text-slate-50 hover:text-green-700 hover:bg-green-100 ml-2"
        : "bg-slate-200 hover:text-slate-50 hover:bg-slate-400 ml-2";
    const dislikeIconClass = userDidDisliked
        ? "bg-red-700 text-slate-50 hover:text-red-700 hover:bg-red-100"
        : "bg-slate-200 hover:text-slate-50 hover:bg-slate-400";

    return (
        <div className="flex justify-items-center ml-2">
            {!item.reports.includes(user._id) && (
                <IconButton
                    className="bg-slate-200 hover:text-slate-50 hover:bg-slate-400 ml-2"
                    size="small"
                    onClick={onReport}>
                    <ReportIcon fontSize="10px" />
                </IconButton>
            )}
            <IconButton className={likeIconClass} size="small" onClick={onLike}>
                <ThumbUpIcon fontSize="10px" />
            </IconButton>
            <IconButton className={dislikeIconClass} size="small" onClick={onDislike}>
                <ThumbDownIcon fontSize="10px" />
            </IconButton>
        </div>
    );
};

export default LikeDislikeReport;
