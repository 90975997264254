import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const EditDelete = ({ user, item, author, onDelete, onEdit }) => {
    if (!user || !author || user._id !== author._id) {
        return null;
    }
    return (
        <div className="flex justify-items-center ml-2">
            <IconButton
                className="bg-slate-200 hover:text-slate-50 hover:bg-slate-400 ml-2"
                size="small"
                onClick={onDelete}>
                <DeleteIcon fontSize="10px" />
            </IconButton>
            <IconButton className="bg-slate-200 hover:text-slate-50 hover:bg-slate-400" size="small" onClick={onEdit}>
                <BorderColorIcon fontSize="10px" />
            </IconButton>
        </div>
    );
};

export default EditDelete;
