import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { login, selectUser } from "../app/features/userSlice";
import { SubscripeNowButton } from "./SubscripePrompt";
import { UserUtils, ImageUtils } from "../utils";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  MenuItem,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AudiohatLogo from "../assets/images/Audiohat_Logo.png";
import userEndPoints from "../app/userEndPoints";
//-------------------------------------------------------------------//
//-------------------------------------------------------------------//
function Nav({ onLogo, onSideButton }) {
  const [endPoints, setEndPoints] = useState(userEndPoints);
  const [adminAdded, setAdminAdded] = useState(false);

  const user = useSelector(selectUser);
  const [show, handleShow] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  const handleUserMenu = (item) => {
    setAnchorElUser(null);
    if (item.path === "/switchBack") {
      const adminUser = JSON.parse(localStorage.getItem("admin"));
      localStorage.removeItem("admin");
      dispatch(
        login({
          ...adminUser,
          token: adminUser.loggedInToken,
        }),
      );
      navigate("/");
    } else if (item.path) {
      navigate(item.path);
    }
  };
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  useEffect(() => {
    const transitionNavBar = () => {
      if (window.scrollY > 100) {
        handleShow(true);
      } else {
        handleShow(false);
      }
    };
    window.addEventListener("scroll", transitionNavBar);
    return () => window.removeEventListener("scroll", transitionNavBar);
  }, [handleShow]);
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  useEffect(() => {
    if (user) {
      if (user.isAdmin && !adminAdded) {
        setEndPoints((prevEndPoints) => [
          ...prevEndPoints,
          {
            title: "admin.siteControl",
            path: "/admin",
          },
        ]);
        setAdminAdded(true);
      } else if (!user.isAdmin && adminAdded) {
        setEndPoints((prevEndPoints) => prevEndPoints.filter((item) => item.path !== "/admin"));
        setAdminAdded(false);
      }
    }
  }, [user, adminAdded]);
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  const noHeaderFooter = ["/login", "/register", "/checkout", "/reset-password"];
  if (noHeaderFooter.includes(window.location.pathname)) {
    return null;
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  const paidUser = UserUtils.isPaidUser();
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  return (
    <>
      <AppBar
        position="fixed"
        className="bg-navbar"
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: show ? "rgba(0,0,0,.5)" : "#000",
          transition: "all .5s ease",
          height: `${paidUser ? "80px" : "160px"}`,
        }}>
        <Container maxWidth="md">
          <Toolbar disableGutters>
            {/* --------------------------------------------------------------- */}
            {/* --------------------------------------------------------------- */}
            <Box sx={{ flexGrow: 0 }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={onSideButton}
                color="inherit">
                <MenuIcon />
              </IconButton>
            </Box>
            {/* --------------------------------------------------------------- */}
            {/* --------------------------------------------------------------- */}
            <Box className="p-2" sx={{ flexGrow: 1 }}>
              <div className="flex flex-col justify-center">
                <img
                  className="h-[65px] mx-auto object-contain cursor-pointer"
                  src={AudiohatLogo}
                  alt="Audiohat"
                  onClick={onLogo}
                />
              </div>
            </Box>
            {/* --------------------------------------------------------------- */}
            {/* --------------------------------------------------------------- */}
            <Box>
              {user && (
                <>
                  <Tooltip title={t("user.profile")}>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar alt={user.name} src={ImageUtils.getMixedObjLocation(user)} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleUserMenu}>
                    {endPoints.map((userEndPoint) => {
                      if (userEndPoint.devider === true) {
                        return <Divider key={userEndPoint.title} />;
                      } else if (
                        (userEndPoint.title === "user.switch" && user.temporary) ||
                        userEndPoint.title !== "user.switch"
                      ) {
                        return (
                          <MenuItem
                            key={userEndPoint.title}
                            onClick={() => handleUserMenu(userEndPoint)}
                            selected={window.location.pathname === userEndPoint.path}>
                            <Typography textAlign="center">{t(`${userEndPoint.title}`)}</Typography>
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Menu>
                </>
              )}
              {/* --------------------------------------------------------------- */}
              {/* --------------------------------------------------------------- */}
              {!user && (
                <div className="white-links">
                  <IconButton
                    aria-label="login"
                    color="white"
                    onClick={() =>
                      navigate("/login", {
                        replace: true,
                        state: { type: "login" },
                      })
                    }>
                    <LockOpenIcon />
                  </IconButton>
                  <IconButton
                    aria-label="register"
                    color="white"
                    onClick={() =>
                      navigate("/register", {
                        replace: true,
                        state: { type: "register" },
                      })
                    }>
                    <PersonAddIcon />
                  </IconButton>
                </div>
              )}
              {/* --------------------------------------------------------------- */}
              {/* --------------------------------------------------------------- */}
            </Box>
          </Toolbar>
          {/* --------------------------------------------------------------- */}
          {/* --------------------------------------------------------------- */}
          {paidUser === false && (
            <Toolbar disableGutters>
              <SubscripeNowButton path="/plans" />
            </Toolbar>
          )}
        </Container>
      </AppBar>
    </>
  );
}
export default Nav;
