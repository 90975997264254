import { Outlet, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { UserUtils } from "../utils";

import "react-toastify/dist/ReactToastify.css";

const NoHeaderFooterLayout = () => {
    const location = useLocation();
    const { toastMessage, toastType, toastId } = location.state || {};
    if (toastMessage) {
        toast[toastType](toastMessage, {
            toastId: toastId,
            position: toast.POSITION.TOP_CENTER,
        });
        window.history.replaceState(null, "", location.pathname);
    }

    const user = UserUtils.getUser();
    UserUtils.getGeolocation();
    return (
        <>
            <Outlet context={{ user }} />
            <ToastContainer rtl />
        </>
    );
};

export default NoHeaderFooterLayout;
