import { Box, Stack, Pagination, PaginationItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ItemsPagination = ({ totalPages, currentPage, classes, onPageChange }) => {
    return (
        <Box className={`${classes}`}>
            <Stack spacing={2}>
                <Pagination
                    dir="rtl"
                    className="flex justify-center"
                    count={totalPages}
                    defaultPage={1}
                    onChange={(e, page) => onPageChange(page)}
                    page={currentPage}
                    variant="outlined"
                    shape="rounded"
                    renderItem={(item) => (
                        <PaginationItem slots={{ next: ArrowForwardIcon, previous: ArrowBackIcon }} {...item} />
                    )}
                />
            </Stack>
        </Box>
    );
};

export default ItemsPagination;
