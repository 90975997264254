import { Box, Avatar, Typography, IconButton, Tooltip } from "@mui/material";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@mui/lab";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReplyIcon from "@mui/icons-material/Reply";

import Moment from "react-moment";
import moment from "moment";

import BookCommentBar from "./BookCommentBar";

import { useTranslation } from "react-i18next";
import { ImageUtils, UserUtils } from "../../utils";
import LikeDislikeReport from "./LikeDislikeReport";
import EditDelete from "./EditDelete";

import "../../assets/css/BookDetailsComments.css";

const BookComments = ({
  comments,
  onAddComment,
  onEditComment,
  onDeleteComment,
  onAddReply,
  onEditReply,
  onDeleteReply,
  onLikeComment,
  onDislikeComment,
  onLikeReply,
  onDislikeReply,
  onReportComment,
  onReportReply,
}) => {
  const { t } = useTranslation();
  const user = UserUtils.getUser();
  const commentsTitle = (text) => {
    return (
      <Box className="book-details-comments">
        <div className="line" />
        <div className="title">
          <Tooltip title={t("book.details.addComment")}>
            <IconButton aria-label="add-comment" onClick={onAddComment}>
              <MapsUgcIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="h2" align="center">
            {text}
          </Typography>
        </div>
        <div className="line" />
      </Box>
    );
  };

  if (comments.length <= 0) {
    return commentsTitle(t("book.no_comments"));
  }

  return (
    <>
      {commentsTitle(t("book.comments"))}
      <Timeline position="right">
        {comments.map((comment) => (
          <TimelineItem key={comment._id}>
            <TimelineOppositeContent
              sx={{
                fontSize: "0.85rem",
                textAlign: "left",
                flex: "none",
                width: "70px",
                padding: 0,
              }}
              color="text.secondary"
            />

            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot
                sx={{
                  padding: "0px !important",
                  flex: "none",
                }}
              >
                <Avatar
                  alt={comment.author.name}
                  src={ImageUtils.getMixedObjLocation(comment.author)}
                />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent
              sx={{
                py: "12px",
                px: 2,
                borderBottom: "1px solid rgba(0,0,0,0.1)",
                textAlign: "right",
                flex: 1,
              }}
            >
              <div className="flex justify-between content-center items-center">
                <div className="flex justify-between content-center items-center">
                  <Typography variant="h6">{comment.author.name}</Typography>
                  <IconButton
                    onClick={() => onAddReply(comment)}
                    disableRipple={true}
                  >
                    <ReplyIcon />
                  </IconButton>
                </div>
                <div className="flex justify-between content-center items-center">
                  <EditDelete
                    user={user}
                    item={comment}
                    author={comment.author}
                    onDelete={() => onDeleteComment(comment)}
                    onEdit={() => onEditComment(comment)}
                  />
                  <LikeDislikeReport
                    user={user}
                    item={comment}
                    author={comment.author}
                    onLike={() => onLikeComment(comment)}
                    onDislike={() => onDislikeComment(comment)}
                    onReport={() => onReportComment(comment)}
                  />
                  <Typography variant="time-small">
                    <AccessTimeIcon fontSize="small" className="ml-2" />
                    <Moment fromNow>{moment(comment.createdAt)}</Moment>
                  </Typography>
                </div>
              </div>
              <Typography>{comment.content}</Typography>
              {comment.replies.length > 0 && (
                <BookCommentBar
                  user={user}
                  comment={comment}
                  onEditReply={onEditReply}
                  onDeleteReply={onDeleteReply}
                  onLikeReply={(comment, reply) => onLikeReply(comment, reply)}
                  onDislikeReply={(comment, reply) =>
                    onDislikeReply(comment, reply)
                  }
                  onReportReply={(comment, reply) =>
                    onReportReply(comment, reply)
                  }
                />
              )}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
};

export default BookComments;
