import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { IconButton, Stack, Divider } from "@mui/material";

export default function FormIconButtons({ item, handleDelete, direction = "row" }) {
	return (
		<div className="w-[100%]">
			<Stack
				direction={direction}
				justifyContent="center"
				alignItems="center"
				spacing={2}
				divider={<Divider orientation="vertical" flexItem />}
			>
				<IconButton type="submit" className={`${direction === "row" ? "ml-[20px]" : ""}`}>
					<SaveIcon />
				</IconButton>
				{item._id && (
					<IconButton type="button" onClick={handleDelete}>
						<DeleteIcon />
					</IconButton>
				)}
			</Stack>
		</div>
	);
}
