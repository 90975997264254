import Iconify from "../components/iconify";

const EndPoints = [
  {
    title: "titles.user.dashboard",
    path: "/user",
    icon: <Iconify icon="clarity:dashboard-solid" />,
  },
  // {
  //   title: "titles.user.notifications",
  //   path: "/user/notifications",
  //   icon: <Iconify icon="mdi:notifications" />,
  // },
  {
    title: "titles.user.account",
    path: "/user/account",
    icon: <Iconify icon="mdi:account-cog" />,
  },
  {
    title: "titles.user.profile",
    path: "/user/profile",
    icon: <Iconify icon="icomoon-free:profile" />,
  },
  // devider
  // {
  //   devider: true,
  //   title: "devider-0",
  // },
  // {
  //   title: "titles.user.log",
  //   path: "/user/log",
  //   icon: <Iconify icon="material-symbols:history" />,
  // },
  // {
  //   title: "titles.user.favorites",
  //   path: "/user/favorites",
  //   icon: <Iconify icon="mdi:favorite-cog" />,
  // },
  // devider
  {
    devider: true,
    title: "devider-1",
  },
  {
    title: "user.logout",
    path: "/logout",
    icon: <Iconify icon="ep:turn-off" />,
  },
  {
    title: "user.switch",
    path: "/switchBack",
    icon: <Iconify icon="ep:turn-off" />,
  },
];

export default EndPoints;
