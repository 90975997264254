import { Suspense } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "./features/userSlice";

const ProtectedRoute = ({ children }) => {
    const user = useSelector(selectUser);

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    return <Suspense fallback={<>...</>}>{children}</Suspense>;
};

export default ProtectedRoute;
