import { axios, requests } from "../../index";

const plansLoader = async () => {
    const { data } = await axios.get(requests.fetchPlans);
    if (data && data.plans) {
        return data.plans;
    }
    return [];
};

export default plansLoader;
