import { useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { motion, useScroll } from "framer-motion";
import { toast, ToastContainer } from "react-toastify";
import { Nav, RootSidebar, Footer, ScrollToTop, CircleBackButton } from "../components";
import { UserUtils } from "../utils";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectUser } from "../app/features/userSlice";
//-------------------------------------------------------------------//
//-------------------------------------------------------------------//
const RootLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { scrollYProgress } = useScroll();
  const { toastMessage, toastType, toastId } = location.state || {};
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  if (toastMessage) {
    toast[toastType](toastMessage, {
      toastId: toastId,
      position: toast.POSITION.TOP_CENTER,
      onClose: () => window.history.replaceState(null, "", location.pathname),
    });
  }

  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  const handelItemClicked = (item) => {
    navigate(item.path);
    setIsSidebarOpen(false);
  };
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  UserUtils.getGeolocation();
  const noHeaderFooter = ["/login", "/register", "/checkout", "/reset-password"];
  const top = noHeaderFooter.includes(location.pathname) ? "" : "pt-[80px] pb-[80px]";
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  return (
    <>
      <Helmet>
        <title>{t("helmet.pageTitles.root")}</title>
      </Helmet>
      {/* ------------------------------------------------------------------*/}
      {/* ------------------------------------------------------------------*/}
      <div className="w-[100vw]">
        <Nav onLogo={() => navigate("/")} onSideButton={() => setIsSidebarOpen(!isSidebarOpen)}></Nav>
        {/* -----------------------------------------*/}
        {/* -----------------------------------------*/}
        <main className={`${top}`}>
          <ScrollToTop />
          <Outlet />
        </main>
        {/* -----------------------------------------*/}
        {/* -----------------------------------------*/}
        <RootSidebar
          isOpen={isSidebarOpen}
          handelClose={() => setIsSidebarOpen(false)}
          onItem={(item) => {
            handelItemClicked(item);
          }}
          user={user}
        />
        {/* -----------------------------------------*/}
        {/* -----------------------------------------*/}
        <motion.div className="progress-bar" style={{ scaleX: scrollYProgress }} />
        {/* -----------------------------------------*/}
        {/* -----------------------------------------*/}
        <Footer />
        {/* -----------------------------------------*/}
        {/* -----------------------------------------*/}
      </div>
      {/* ------------------------------------------------------------------*/}
      {/* ------------------------------------------------------------------*/}
      <ToastContainer rtl />
      {/* ------------------------------------------------------------------*/}
      {/* ------------------------------------------------------------------*/}
      <CircleBackButton />
      <div className="cr cr-top cr-left cr-sticky cr-red">
        <span>نسخة تجريبية</span>
      </div>
    </>
  );
};

export default RootLayout;
