import { ImageUtils } from "../../utils";

const BlurItemBackgroundImage = ({ item }) => {
    const imageUrl = ImageUtils.getMixedObjLocation(item);
    if (!imageUrl) {
        return null;
    }

    return (
        <div className="blurred-background-image">
            <img src={imageUrl} alt={item.title} />
        </div>
    );
};

export default BlurItemBackgroundImage;
