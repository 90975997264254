import { axios, requests } from "../../index";

const booksLoader = async () => {
    const { data } = await axios.get(requests.fetchBooks);
    if (data && data.books) {
        return data.books;
    }
    return [];
};

export default booksLoader;
