import { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// @mui
import { styled } from "@mui/material/styles";
//
import Header from "./AdminHeader";
import UserSideBar from "../components/userSidebar";
import { CircleBackButton } from "../components";

// ----------------------------------------------------------------------
import adminEndPoints from "../app/adminEndPoints";
import { UserUtils } from "../utils";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [finalAdminEndPoints, setFinalAdminEndPoints] = useState([]);

  const location = useLocation();
  const user = UserUtils.getUser();

  // remove some items from adminEndPoints if user.caps not have it
  useEffect(() => {
    if (user?.caps) {
      // bail if user.email
      if (["engr.amr.yousry@gmail.com", "ibo2001@gmail.com"].includes(user.email)) {
        setFinalAdminEndPoints(adminEndPoints);
        return;
      }
      const newEndPoints = adminEndPoints.filter((item) => {
        if (!item.path) {
          return true;
        }

        const path = item.path.split("/").pop();
        return user.caps.includes(`/${path}`);
      });
      setFinalAdminEndPoints(newEndPoints);
    }
  }, [user]);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} user={user} />
      <UserSideBar
        openNav={open}
        onCloseNav={() => setOpen(false)}
        user={user}
        endPoints={finalAdminEndPoints}
        sidebarHeader
      />
      <Main>
        <Outlet context={{ user }} />
      </Main>
      <ToastContainer rtl />
      {location.pathname !== "/admin" && <CircleBackButton />}
    </StyledRoot>
  );
}
