import { Outlet } from "react-router-dom";
import { Container } from "@mui/material";

const RootBooksLayout = () => {
    return (
        <Container maxWidth="xl" className="py-8">
            <Outlet />
        </Container>
    );
};

export default RootBooksLayout;
