import { forwardRef } from "react";

import { useTranslation } from "react-i18next";
import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, Button, TextField } from "@mui/material";
import Draggable from "react-draggable";

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const BookCommentDialog = ({ commentState, onSaveComment, handleClose, bookTitle }, ref) => {
    const { t } = useTranslation();
    const { open, comment, isReply, reply } = commentState;
    const getTitle = () => {
        if (isReply === true && comment) {
            if (reply) {
                return t("book.comment.editReply", { name: reply.author.name });
            }
            return t("book.comment.replyTo", { name: comment.author.name });
        }
        if (comment) {
            return t("book.details.editComment", { title: bookTitle });
        }
        return t("book.details.addCommentTo", { title: bookTitle });
    };
    const getContent = () => {
        if (isReply === true) {
            if (reply) {
                return reply.content;
            }
            return "";
        }
        if (comment) {
            return comment.content;
        }

        return "";
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            sx={{
                "& .MuiDialog-paper": {
                    width: "640px",
                    maxWidth: "90%",
                },
            }}>
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                {getTitle()}
            </DialogTitle>
            <DialogContent
                sx={{
                    overflow: "visible",
                }}>
                <TextField
                    inputRef={ref}
                    label={t("book.details.commentContent")}
                    name="content"
                    rows={4}
                    autoFocus
                    fullWidth
                    multiline
                    defaultValue={getContent()}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="black-and-white" onClick={() => onSaveComment(comment, reply)}>
                    {t("Save")}
                </Button>
                <Button variant="black-and-white" className="mr-8" onClick={handleClose}>
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default forwardRef(BookCommentDialog);
