import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import LibraryBooksTwoToneIcon from "@mui/icons-material/LibraryBooksTwoTone";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import DrawTwoToneIcon from "@mui/icons-material/DrawTwoTone";
import SubscriptionsTwoToneIcon from "@mui/icons-material/SubscriptionsTwoTone";
const items = [
    {
        name: "home",
        path: "/",
        icon: <HomeTwoToneIcon />,
    },
    {
        name: "books",
        path: "/books",
        icon: <LibraryBooksTwoToneIcon />,
    },
    {
        name: "categories",
        path: "/categories",
        icon: <MenuTwoToneIcon />,
    },
    {
        name: "authors",
        path: "/authors",
        icon: <DrawTwoToneIcon />,
    },
    {
        name: "serieses",
        path: "/serieses",
        icon: <SubscriptionsTwoToneIcon />,
    },
];

export default items;
