import { useTranslation } from "react-i18next";
import { Badge, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { UserUtils } from "../../utils";
import Iconify from "../iconify";

const StyledParagraph = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: "0.9rem",
    marginLeft: theme.spacing(1),
}));

const SubscriptionPlanInfo = ({ user }) => {
    const { t } = useTranslation();
    // convert activeUntil to moment object
    const activeUntil = UserUtils.getActiveUntil(user);
    const planInfo = UserUtils.getUserPlanTitle(user); //user?.plan?.title ? user.plan.title : t("user.unknownPlanName");
    return (
        <>
            <Badge>
                <Iconify icon={planInfo.store} />
                <span className="text-gray-400 mr-2">{planInfo.title}</span>
            </Badge>
            {activeUntil && (
                <StyledParagraph>
                    {activeUntil < Date.now() ? (
                        <span className="text-red-600 underline">
                            {t("user.expiredSubscriptionIn", { date: activeUntil.calendar() })}
                        </span>
                    ) : (
                        <span className="text-lime-600 underline text-xs pr-6">
                            {t("user.activeSubscriptionUntil", { date: activeUntil.calendar() })}
                        </span>
                    )}
                </StyledParagraph>
            )}
        </>
    );
};

export default SubscriptionPlanInfo;
