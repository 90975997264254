import { axios, requests } from "../../index";

const adminSupportLoader = async () => {
  const { data } = await axios.get(`${requests.admin.support}`);
  console.log(data);
  if (data && data.messages) {
    return data.messages;
  }
  return null;
};

export default adminSupportLoader;
