import { useState } from "react";
import { ImageUtils } from "../../utils";

import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FreeBookBadge from "./FreeBookBadge";

import { requests, axios } from "../../api";
import { UserUtils } from "../../utils";

const BookThumbnail = ({ item }) => {
    const user = UserUtils.getUser();
    const [isFavorite, setIsFavorite] = useState(item.isFavorite);

    const handleToggleFavorite = async () => {
        try {
            const { data } = await axios.post(requests.toggleFavorite, {
                bookId: item._id,
            });
            if (data.favorites) {
                user.favBooks = data.favorites;
                UserUtils.setUser(user);
                setIsFavorite(!isFavorite);
            } else {
                alert(data.message);
            }
        } catch (error) {
            alert(error);
        }
    };

    return (
        <div className="relative">
            <IconButton
                size="large"
                sx={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    color: "rgba(239,68,68,1)",
                    padding: 1,
                    backgroundColor: "rgba(0,0,0,0.2) !important",
                    "&:hover": {
                        backgroundColor: "rgba(255,255,255,0.2) !important",
                        color: "#ef4444",
                    },
                }}
                onClick={() => handleToggleFavorite()}>
                {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </IconButton>
            {item.isFree && <FreeBookBadge size="large" />}

            <img
                className="shadow shadow-slate-950 over-flow-hidden rounded-md"
                src={ImageUtils.getMixedObjLocation(item.thumbnail)}
                alt={item.title}
            />
        </div>
    );
};

export default BookThumbnail;
