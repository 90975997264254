import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Moment from "react-moment";
import moment from "moment";
// @mui
import { styled } from "@mui/material/styles";
import { Link, Card, Grid, Avatar, Typography, CardContent } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarTodayTwoTone";
import SvgColor from "../svg-color";
import { ImageUtils } from "../../utils";
// ----------------------------------------------------------------------

const StyledCardMedia = styled("div")({
    position: "relative",
    paddingTop: "calc(100% * 3 / 4)",
});

const StyledTitle = styled(Link)({
    overflow: "hidden",
    WebkitLineClamp: 2,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    cursor: "pointer",
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    zIndex: 9,
    width: 32,
    height: 32,
    position: "absolute",
    left: theme.spacing(3),
    bottom: theme.spacing(-2),
}));

const StyledCover = styled("img")({
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
});

// ----------------------------------------------------------------------

BookCard.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number,
};

export default function BookCard({ item }) {
    const navigate = useNavigate();
    const { _id, title, subtitle, author, thumbnail, scheduled } = item;
    return (
        <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ position: "relative" }}>
                <StyledCardMedia>
                    <SvgColor
                        color="paper"
                        src="/assets/icons/shape-avatar.svg"
                        sx={{
                            width: 80,
                            height: 36,
                            zIndex: 9,
                            bottom: -15,
                            left: 0,
                            position: "absolute",
                            color: "background.paper",
                        }}
                    />
                    <StyledAvatar alt={author.name} src={author.avatar.location} />
                    <StyledCover alt={title} src={ImageUtils.getMixedObjLocation(thumbnail)} />
                </StyledCardMedia>
                <Typography
                    variant="caption"
                    sx={{ pl: "80px", pr: 3, textAlign: "left", color: "text.disabled", display: "block" }}>
                    {author.name}
                </Typography>
                <CardContent sx={{ pt: 1 }}>
                    <Typography variant="caption" sx={{ color: "text.disabled", display: "block" }}>
                        <CalendarTodayIcon fontSize="12" className="ml-2" />
                        <Moment to={moment(scheduled)} local="ar" />
                    </Typography>

                    <StyledTitle
                        color="inherit"
                        variant="subtitle2"
                        underline="hover"
                        onClick={() => navigate(`/books/${_id}`)}>
                        {title}
                    </StyledTitle>
                    <Typography variant="caption" sx={{ color: "text.disabled", display: "block" }}>
                        {subtitle}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
}
