import { axios, requests } from "../../index";

const adminPlansLoader = async () => {
	const { data } = await axios.get(`${requests.admin.plans}`);
	if (data && data.plans) {
		return data.plans;
	}
	return null;
};

export default adminPlansLoader;
