import { axios, requests } from "../../index";

const reportedRepliesLoader = async () => {
    const { data } = await axios.get(`${requests.admin.replies}/reported`);
    if (data && data.replies) {
        return data.replies;
    }
    return [];
};

export default reportedRepliesLoader;
