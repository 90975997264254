import tabs from "./tabs";
import form from "./form";

const user = {
  form: { ...form, tabs: tabs },
  count: {
    total: "إجمالي المستخدمين: {{count}}",
    active: "{{count}} مستخدم ساري",
    inactive: "{{count}} مستخدم منتهي",
    subscribed: "{{count}} مستخدم مشترك",
    notSubscribed: "{{count}} مستخدم غير مشترك",
    // total_zero: "إجمالي عدد المستخدمين: صفر مستخدم",
    // total_one: "إجمالي عدد المستخدمين: مستخدم واحد",
    // total_two: "إجمالي عدد المستخدمين: مستخدمان",
    // total_few: "إجمالي عدد المستخدمين: {{count}} مستخدمين",
    // total_other: "إجمالي عدد المستخدمين: {{count}} مستخدم",
    // notSubscribed_zero: "لا يوجد أي مستخدم غير مشترك",
    // notSubscribed_one: "مستخدم واحد غير مشترك",
    // notSubscribed_two: "مستخدمان غير مشتركان",
    // notSubscribed_few: "{{count}} مستخدمين غير مشتركين",
    // notSubscribed_many: "{{count}} مستخدم غير مشترك",
    // notSubscribed_other: "{{count}} مستخدم غير مشترك",
    // subscribed_zero: "لا يوجد مستخدمين مشتركين",
    // subscribed_one: "مستخدم مشترك واحد",
    // subscribed_two: "مستخدمان مشتركان",
    // subscribed_few: "{{count}} مستخدمين مشتركين",
    // subscribed_many: "{{count}} مستخدم مشترك",
    // subscribed_other: "{{count}} مستخدم مشترك",
    sendEmailToAll: "إرسال بريد للكل",
  },
};

export default user;
