import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { ImageUtils } from "../../utils";
import NewMediaIcon from "../../assets/images/NewMedia.svg";

const StyledButton = styled(IconButton)(({ theme }) => ({
  padding: "0 10px 0 10px",
  margin: "0 10px 0 10px",
}));

function InputField(uploadType, handleChange) {
  // if uploadType is "audio", then accept audio/* and video/* files else accept image/* files
  const accept = uploadType === "audio" ? "audio/*,video/*" : "image/*";
  return (
    <input
      id={`audiohat-uploader-${uploadType}`}
      className="hidden"
      type="file"
      onChange={handleChange}
      accept={accept}
      data-upload_type={uploadType}
    />
  );
}

export default function AppUploader({ item, uploadType, titleKey, handleChange, handleDelete }) {
  //console.log("AppUploader", item?.audioFile?.originalname);
  const { t } = useTranslation();

  if (uploadType === "audio") {
    const fileUrl = item.audioFile ? item.audioFile.location : null;
    if (!fileUrl) {
      return (
        <>
          <label htmlFor={`audiohat-uploader-${uploadType}`}>
            <img className="h-[80px] w-[80px] object-contain cursor-pointer" src={NewMediaIcon} alt={item[titleKey]} />
          </label>
          {InputField(uploadType, handleChange)}
        </>
      );
    }

    const isVideo = item.audioFile.mimetype ? item.audioFile.mimetype.includes("video") : false;
    if (isVideo) {
      return (
        <>
          <div className="flex flex-row">
            <div className="flex flex-col items-center">
              <IconButton aria-label={t("Delete")} disableRipple onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
              <IconButton aria-label={t("Upload")} disableRipple>
                <label htmlFor={`audiohat-uploader-${uploadType}`} className="cursor-pointer">
                  <CloudUploadIcon />
                </label>
                {InputField(uploadType, handleChange)}
              </IconButton>
            </div>
            <video
              controls={true}
              src={fileUrl}
              className="h-[100px]"
              poster={ImageUtils.getMixedObjLocation(item, "poster")}
            />
            {item && item.audioFile && item.audioFile.originalname && <h3>{item.audioFile.originalname}</h3>}
          </div>
        </>
      );
    }

    return (
      <div className="p-0 pl-2 my-2 flex flex-col h-[80px] items-center">
        <div className="flex justify-between">
          <StyledButton aria-label={t("Delete")} disableRipple onClick={handleDelete}>
            <DeleteIcon />
          </StyledButton>
          <StyledButton aria-label={t("Upload")} disableRipple>
            <label htmlFor={`audiohat-uploader-${uploadType}`} className="cursor-pointer">
              <CloudUploadIcon />
            </label>
            {InputField(uploadType, handleChange)}
          </StyledButton>
        </div>
        <audio controls={true} src={fileUrl} />
        {item && item.audioFile && item.audioFile.originalname && <h3>{item.audioFile.originalname}</h3>}
      </div>
    );
  }

  return (
    <>
      <label htmlFor={`audiohat-uploader-${uploadType}`}>
        <img
          className="ml-4 h-[100px] w-[100px] object-contain cursor-pointer"
          src={ImageUtils.getMixedObjLocation(item, "poster")}
          alt={item[titleKey]}
        />
      </label>
      {InputField(uploadType, handleChange)}
    </>
  );
}
