import { Fragment } from "react";
class StringUtils {
    static capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static truncate(string, length) {
        if (string?.length > length) {
            return string.substring(0, length - 1) + "...";
        } else {
            return string;
        }
    }

    static NewlineText({ text, ...props }) {
        if (!text) return null;

        return text.split("\n").map((str, index) => {
            // bail if empty string
            if (str.trim() === "") return null;
            return <p key={index}>{str}</p>;
        });
    }
    static lineBreaks(text) {
        if (!text) return null;
        return text.split("\n").map((item, key) => {
            return (
                <Fragment key={key}>
                    {item}
                    <br />
                </Fragment>
            );
        });
    }

    static amountFromCartDescription(description) {
        const amount = description.split(" | | ");
        if (amount && amount.length > 0) {
            // return last part of amount
            return amount[amount.length - 1];
        }
        return "";
    }
}

export default StringUtils;
