import { useMatches } from "react-router-dom";
import { Container, Breadcrumbs } from "@mui/material";

function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
}

const AppBreadcrumbs = () => {
    const matches = useMatches();
    const crumbs = matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => match.handle.crumb(match.data));

    return (
        <Container
            maxWidth="3xl"
            role="presentation"
            onClick={handleClick}
            sx={{
                backgroundColor: "rgb(249 250 251)",
            }}>
            <Breadcrumbs aria-label="breadcrumb">
                {crumbs.map((crumb, index) => {
                    return <div key={index}>{crumb}</div>;
                })}
            </Breadcrumbs>
        </Container>
    );
};

export default AppBreadcrumbs;
