import * as React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export default function FormDateTimePicker({ itemDate, ...props }) {
    const { t } = useTranslation();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                        width: "100%",
                        fontFamily: "Droid Serif",
                        flexDirection: "row-reverse",
                    },
                }}
                value={itemDate ? dayjs(itemDate) : null}
                format="DD/MM/YYYY LT"
                localeText={{
                    toolbarTitle: t("dateTimePicker.toolbarTitle"),
                    okButtonLabel: t("dateTimePicker.okButtonLabel"),
                    cancelButtonLabel: t("dateTimePicker.cancelButtonLabel"),
                    clearButtonLabel: t("dateTimePicker.clearButtonLabel"),
                    todayButtonLabel: t("dateTimePicker.todayButtonLabel"),
                }}
                {...props}
            />
        </LocalizationProvider>
    );
}
