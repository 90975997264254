import moment from "moment";

class MomentUtils {
  static formatNiceLocalTime(date) {
    return moment(date).locale("ar_DZ").format("YYYY/M/D الساعة h:mm a");
  }
  static shortFormat(date) {
    return moment(date).locale("ar_DZ").format("YYYY/M/D الساعة h:mm a");
  }
  static getTomorrow() {
    return moment().add(1, "days");
  }

  static threeYearsAgo() {
    return moment().subtract(3, "years");
  }

  static formatForSupportChat(date) {
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");
    const thisWeek = moment().startOf("week");
    const thisYear = moment().startOf("year");

    if (moment(date).isSame(today, "day")) {
      return moment(date).locale("ar_DZ").format("h:mm a");
    }
    if (moment(date).isSame(yesterday, "day")) {
      return "أمس";
    }
    if (moment(date).isSameOrAfter(thisWeek)) {
      return moment(date).locale("ar_DZ").format("dddd");
    }
    if (moment(date).isSameOrAfter(thisYear)) {
      return moment(date).locale("ar_DZ").format("D - MMM");
    }
    return moment(date).locale("ar_DZ").format("D - MMM - YYYY");
  }
}

export default MomentUtils;
