import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";

const CardForm = styled(Card)(({ theme }) => ({
	overflowX: "scroll",
	"&::-webkit-scrollbar": {
		display: "none",
	},
	"&": {
		msOverflowStyle: "none",
		scrollbarWidth: "none",
	},
}));
export default CardForm;
