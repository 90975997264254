import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { Box, Drawer } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
//import NotificationsPopover from "./NotificationsPopover";
import SidebarContent from "./SidebarContent";
// ----------------------------------------------------------------------

const NAV_WIDTH = 200;

UserSideBar.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function UserSideBar({ openNav, onCloseNav, endPoints, bottomChildren, sidebarHeader }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}>
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          anchor="right"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}>
          <SidebarContent
            endPoints={endPoints}
            // children={<NotificationsPopover />}
            bottomChildren={bottomChildren}
            sidebarHeader={sidebarHeader}
          />
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          anchor="right"
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
          SlideProps={{
            timeout: 200,
            direction: "left",
          }}>
          <SidebarContent
            endPoints={endPoints}
            // children={<NotificationsPopover />}
            bottomChildren={bottomChildren}
            sidebarHeader={sidebarHeader}
          />
        </Drawer>
      )}
    </Box>
  );
}
