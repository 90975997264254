import moment from "moment";
import { useTranslation } from "react-i18next";
import ImageUtils from "./images";
import { store } from "../app/store";
//-----------------------------------------------------------------------------//
//-----------------------------------------------------------------------------//
function Translate(key) {
  const { t } = useTranslation();
  return t(key);
}
//-----------------------------------------------------------------------------//
//-----------------------------------------------------------------------------//
class UserUtils {
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static getUser() {
    return store.getState().user.user;
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static getActiveUntil(user) {
    if (!user) return null;
    if (user.revenuecat?.entitlements?.Subscribed_users?.expires_date) {
      return moment(user.revenuecat.entitlements.Subscribed_users.expires_date);
    }

    if (!user.activeUntil) return null;
    if (user.activeUntil === "0000-00-00") return null;

    return moment(user.activeUntil);
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static isPaidUser() {
    const user = this.getUser();
    if (!user) return false;
    const activeUntil = this.getActiveUntil(user);
    // bail if no activeUntil
    if (!activeUntil) return false;
    const now = moment();
    return activeUntil.isAfter(now);
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static canAddComment(user) {
    //--------------------------------------//
    if (!user) {
      return { can: false, message: "book.comment.alerts.notLoggedIn" };
    }
    //--------------------------------------//
    if (user.isAdmin) {
      return { can: true, message: "" };
    }
    //--------------------------------------//
    const activeUntil = this.getActiveUntil(user);
    if (!activeUntil) {
      return { can: false, message: "book.comment.alerts.notSubscribed" };
    }
    //--------------------------------------//
    const now = moment();
    if (activeUntil.isAfter(now)) {
      if (typeof user.canComment === "boolean") {
        if (user.canComment) return { can: true, message: "" };
        else return { can: false, message: "book.comment.alerts.banned" };
      }
      if (!user.canComment?.value && user.canComment?.time > moment().valueOf())
        return { can: false, message: "book.comment.alerts.banned" };
      else return { can: true, message: "" };
    }
    //--------------------------------------//
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static setUser(user) {
    user.avatar = ImageUtils.getMixedObjLocation(user);
    localStorage.setItem("user", JSON.stringify(user));
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static setActiveUntil(activeUntil) {
    const user = this.getUser();
    user.activeUntil = activeUntil;
    this.setUser(user);
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static removeUser() {
    localStorage.removeItem("user");
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static isLoggedIn() {
    return !!this.getUser();
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static logout() {
    this.removeUser();
    // window.location.href = "/";
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static async asyncGeoLocation() {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    localStorage.setItem("geolocation", JSON.stringify(data));

    return data;
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static getGeolocation() {
    // check if geolocation is already stored in local storage
    const jsonGeolocation = localStorage.getItem("geolocation");
    if (jsonGeolocation) {
      const geolocation = JSON.parse(jsonGeolocation);
      return geolocation;
    }

    return this.asyncGeoLocation();
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static storeHumized(store) {
    if (store === "app_store") return "App Store";
    if (store === "play_store") return "Play Store";
    if (store === "stripe") return "Stripe";
    if (store === "promotional") return "Promotional";
    return store;
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static planHumized(plan) {
    const cleanPlan = plan.replace("rc_promo_Subscribed_users_", "");
    switch (cleanPlan) {
      case "audiohat.onemonth.subscription":
      case "com.audiohatdar.app.1month":
        return "الباقة الشهرية";
      case "audiohat.3month.subscription":
      case "com.audiohatdar.app.3month":
        return "الباقة الثلاثية";
      case "audiohat.oneyear.subscription":
      case "com.audiohatdar.app.1year":
        return "الباقة السنوية";
      case "weekly":
        return "الباقة الأسبوعية";
      case "monthly":
        return "الباقة الشهرية";
      case "two_month":
        return "الباقة الشهرين";
      case "yearly":
        return "الباقة السنوية";
      case "three_month":
        return "الباقة الثلاثية";
      case "six_month":
        return "الباقة الستة أشهر";
      case "one_year":
        return "الباقة السنوية";
      case "lifetime":
        return "الباقة مدى الحياة";
      case "daily":
        return "الباقة اليومية";
      case "three_day":
        return "الباقة الثلاثية الأيام";
      default:
        return cleanPlan;
    }
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static iconFromStroe(store) {
    if (store === "app_store") return "ic:baseline-apple";
    if (store === "play_store") return "uil:android";
    if (store === "stripe") return "la:cc-stripe";
    if (store === "promotional") return "devicon:woocommerce-wordmark";
    return store;
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
  static getUserPlanTitle(user) {
    if (!user) return null;
    const product_identifier =
      user.revenuecat?.entitlements?.Subscribed_users?.product_identifier ??
      null;
    console.log("product_identifier", product_identifier);

    if (product_identifier) {
      const { store } = user.revenuecat.subscriptions[product_identifier];
      if (store) {
        return {
          title: this.planHumized(product_identifier),
          store: this.iconFromStroe(store),
        };
      }
    }

    const planTitle = user?.plan?.title
      ? user.plan.title
      : Translate("user.unknownPlanName");
    return { title: planTitle, store: "wpf:online" };
  }
  //-------------------------------------------------------------------//
  //-------------------------------------------------------------------//
}
//-----------------------------------------------------------------------------//
//-----------------------------------------------------------------------------//
export default UserUtils;
