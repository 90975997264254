import axios from "axios";
import { toast } from "react-toastify";
import { store } from "../app/store";
const api_url =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;
// const api_url = "https://api-v2.aws.audiohatdar.com/api/v3";
// const api_url = "https://ws.audiohatdar.com/api/v3";

console.log("api_url", api_url);
console.log("process.env", process.env);

const instance = axios.create({
  baseURL: api_url,
  //time out after 10 minutes
  timeout: 600000,
  headers: {
    Accept: "application/json",
  },
});

instance.interceptors.request.use(
  function (config) {
    const user = store.getState().user.user;
    if (user && user.token) {
      config.headers["x-auth-token"] = user.token;
    }
    return config;
  },
  function (error) {
    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message, { toastId: error.code });
    } else {
      toast.error(error.message, { toastId: error.code });
    }
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.message) {
      toast.error(error.message, { toastId: error.code });
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      toast.error(error.response.data.message, { toastId: error.code });
    } else {
      toast.error(error.message, { toastId: error.code });
    }
    return Promise.reject(error);
  }
);

export default instance;
