import user from "./user";
import book from "./book";
import sidebar from "./sidebar.json";
import authors from "./authors.json";
import announcement from "./announcement.json";
import category from "./category.json";
import series from "./series.json";
import plan from "./plan.json";
import statics from "./statics.json";
import table from "./table.json";

const admin = {
  siteControl: "التحكم بالموقع",
  addBook: "إضافة كتاب",
  announcement: announcement,
  category: category,
  authors: authors,
  series: series,
  table: table,
  plan: plan,
  statics: statics,
  user: user,
  book: book,
  sidebar: sidebar,
  comments: {
    approveCommentConfirm: "هل أنت متأكد من الموافقة على هذا التعليق؟",
    deleteCommentConfirm: "هل أنت متأكد من حذف هذا التعليق؟",
    approveCommentSuccess: "تمت الموافقة على التعليق بنجاح",
    approveCommentFailed: "فشلت عملية الموافقة على التعليق",
    restoreCommentConfirm: "هل أنت متأكد من إستعادة هذا التعليق ؟",
    restoreCommentSuccess: "تمت إستعادة التعليق بنجاح",
    restoreCommentFailed: "فشلت عملية إستعادة التعليق",
    deleteCommentSuccess: "تم حذف التعليق بنجاح",
    deleteCommentFailed: "فشلت عملية حذف التعليق",
  },
  replies: {
    approveReplyConfirm: "هل أنت متأكد من الموافقة على هذا الرد؟",
    deleteReplyConfirm: "هل أنت متأكد من حذف هذا الرد؟",
    approveReplySuccess: "تمت الموافقة على الرد بنجاح",
    approveReplyFailed: "فشلت عملية الموافقة على الرد",
    restoreReplyConfirm: "هل أنت متأكد من إستعادة هذا الرد؟",
    restoreReplySuccess: "تمت إستعادة الرد بنجاح",
    restoreReplyFailed: "فشلت عملية إستعادة الرد",
    deleteReplySuccess: "تم حذف الرد بنجاح",
    deleteReplyFailed: "فشلت عملية حذف الرد",
  },
  support: {
    send: "إرسال",
    message: "الرسالة",
    typeMessage: "اكتب رسالتك هنا",
    messageSent: "تم إرسال الرسالة بنجاح",
    messageFailed: "حدث خطأ أثناء إرسال الرسالة",
    messageRequired: "الرسالة مطلوبة",
    messageDeleted: "تم حذف الرسالة بنجاح",
    messageDeleteFailed: "حدث خطأ أثناء حذف الرسالة",
    messageDeleteConfirmation: "هل أنت متأكد من حذف الرسالة؟",
    messageDelete: "حذف الرسالة",
    messageDeleteCancel: "إلغاء",
    messageDeleteConfirm: "نعم",
    deleteAllUserMessages: "حذف جميع الرسائل من {{name}}",
    deleteAllUserMessagesConfirmation: "هل أنت متأكد من حذف جميع الرسائل من {{name}}؟",
    deleteAllUserMessagesSuccess: "تم حذف جميع الرسائل من {{name}} بنجاح",
    deleteAllUserMessagesFailed: "حدث خطأ أثناء حذف جميع الرسائل من {{name}}",
    deleteAllUserMessagesConfirm: "نعم",
    deleteAllUserMessagesCancel: "إلغاء",
    copyTheMessage: "نسخ الرسالة",
    copyTheMessageSuccess: "تم نسخ الرسالة بنجاح",
    email: "البريد الإلكتروني",
    phone: "الهاتف",
    location: "الموقع",
    device: "الجهاز",
    deviceName: "اسم الجهاز",
    osName: "نظام التشغيل",
    osVersion: "إصدار نظام التشغيل",
    appVersion: "إصدار التطبيق",
  },
};

export default admin;
