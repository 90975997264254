import { axios, requests } from "../../index";

const categoryBooksLoader = async ({ params }) => {
    const { data } = await axios.get(`${requests.categories}/${params.id}/books`);
    if (data && data.books) {
        const { data: data2 } = await axios.get(`${requests.home}/categories`);
        return {
            allBooks: data.books,
            categories: data2.items || [],
        };
    }
    return null;
};

export default categoryBooksLoader;
