import { axios, requests } from "../../index";

const reportedCommentsLoader = async () => {
    const { data } = await axios.get(`${requests.admin.comments}/reported`);
    if (data && data.comments) {
        return data.comments;
    }
    return null;
};

export default reportedCommentsLoader;
