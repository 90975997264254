import admin from "./ar/admin";
import table from "./ar/table.json";
import toast from "./ar/toast.json";
import plans from "./ar/plans.json";
import checkout from "./ar/checkout.json";
import errors from "./ar/errors.json";
import paytabsResults from "./ar/paytabsResults.json";
import fawryResults from "./ar/fawryResults.json";
import settings from "./ar/settings.json";

import translation from "./ar/translation.json";

const resources = {
  ar: {
    translation: {
      ...translation,
      admin: admin,
      table: table,
      toast: toast,
      plans: plans,
      checkout: checkout,
      errors: errors,
      settings: settings,
      paytabsResults: paytabsResults,
      fawryResults: fawryResults,
    },
  },
};
export default resources;
