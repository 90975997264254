import favorite from "./favorite.json";
import logs from "./logs.json";
import subscription from "./subscription.json";
import capabilities from "./capabilities.json";
//-------------------------------------------//
const tabs = {
  favorite: favorite,
  logs: logs,
  subscription: subscription,
  capabilities: capabilities,
};
//-------------------------------------------//
export default tabs;
