import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Input(theme) {
    return {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    paddingRight: "0.75rem",
                    paddingLeft: "0.75rem",
                    "&.Mui-disabled": {
                        backgroundColor: "#e2e8f0",
                        "& svg": { color: theme.palette.text.disabled },
                    },
                },
                input: {
                    "&::placeholder": {
                        opacity: 1,
                        color: theme.palette.text.disabled,
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    "&:before": {
                        borderBottomColor: alpha(theme.palette.grey[500], 0.56),
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: alpha(theme.palette.grey[500], 0.12),
                    "&:hover": {
                        backgroundColor: alpha(theme.palette.grey[500], 0.16),
                    },
                    "&.Mui-focused": {
                        backgroundColor: theme.palette.action.focus,
                    },
                    "&.Mui-disabled": {
                        backgroundColor: theme.palette.action.disabledBackground,
                    },
                },
                underline: {
                    "&:before": {
                        borderBottomColor: alpha(theme.palette.grey[500], 0.56),
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: alpha(theme.palette.grey[500], 0.32),
                    },
                    "&.Mui-disabled": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.action.disabledBackground,
                        },
                    },
                    "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.action.focus,
                        },
                    },
                },
            },
        },
    };
}
