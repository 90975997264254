// @mui
import { styled, alpha } from "@mui/material/styles";
import { Typography, Avatar } from "@mui/material";
import { useSelector } from "react-redux";
// components
import Scrollbar from "../scrollbar";
import NavSection from "../nav-section";
//
import { selectUser } from "../../app/features/userSlice";

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 1),
  backgroundColor: alpha(theme.palette.grey[500], 0.4),
  height: 80,
}));

const SidebarContent = ({
  //children,
  bottomChildren,
  endPoints,
  sidebarHeader,
}) => {
  const user = useSelector(selectUser);
  return (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}>
      {sidebarHeader && (
        <StyledAccount>
          <Avatar src={user.avatar} alt={user.name} className="ml-3" />
          <Typography variant="subtitle2" sx={{ color: "text.primary", flexGrow: 1 }}>
            {user.name}
          </Typography>
          {/* {children} */}
        </StyledAccount>
      )}
      <NavSection data={endPoints} />
      {bottomChildren}
    </Scrollbar>
  );
};

export default SidebarContent;
