import { axios, requests } from "../../index";

const adminSeriesesLoader = async () => {
    const { data } = await axios.get(`${requests.admin.serieses}`);
    if (data && data.serieses) {
        return data.serieses;
    }
    return null;
};

export default adminSeriesesLoader;
