import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "::-webkit-scrollbar": {
    width: "5px",
  },

  /* Track */
  "::-webkit-scrollbar-track": {
    background: "white",
  },

  /* Handle */
  "::-webkit-scrollbar-thumb": {
    background: "rgb(206,205,203)",
  },

  /* Handle on hover */
  "::-webkit-scrollbar-thumb:hover": {
    background: "rgb(167, 167, 167)",
  },
};

export default function FormModal({ form, open, handleClose, width = 400 }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...style, width: width, maxHeight: 600, overflow: "auto" }}>
        {form}
      </Box>
    </Modal>
  );
}
