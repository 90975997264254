import { useState } from "react";

import QuickreplyIcon from "@mui/icons-material/Quickreply";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Badge, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ImageUtils, StringUtils } from "../../utils";

import LikeDislikeReport from "./LikeDislikeReport";
import EditDelete from "./EditDelete";
import LikesBadge from "./LikesBadge";
import DislikesBadge from "./DislikesBadge";

import Moment from "react-moment";
import moment from "moment";

const BookCommentBar = ({ user, comment, onEditReply, onDeleteReply, onLikeReply, onDislikeReply, onReportReply }) => {
    const { likes, disLikes, replies } = comment;
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <div className="flex flex-row justify-start items-center">
                {replies && replies.length > 0 && (
                    <>
                        <Accordion
                            expanded={expanded === `panel${comment._id}`}
                            sx={{
                                border: "none",
                                boxShadow: "none",
                                width: "100%",
                                "& .MuiAccordionSummary-root": {
                                    backgroundColor: "#f5f5f5 !important",
                                    border: "1px solid rgba(0,0,0,0.2) !important",
                                },
                                "& .Mui-expanded": {},
                                "& .MuiAccordionSummary-content": {},
                                "& .MuiAccordionSummary-expandIconWrapper": {},
                                "& .MuiAccordionDetails-root": {
                                    backgroundColor: "#f5f5f5 !important",
                                    border: "1px solid rgba(0,0,0,0.2) !important",
                                    borderTop: "none !important",
                                },
                            }}
                            onChange={handleChange(`panel${comment._id}`)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${comment._id}bh-content`}
                                id={`panel${comment._id}bh-header`}>
                                <Badge
                                    badgeContent={replies.length}
                                    sx={{
                                        "& .MuiBadge-badge": {
                                            right: 0,
                                            top: 13,
                                            border: `2px solid ${"white"}`,
                                            padding: "0 4px",
                                            backgroundColor: "#164e63",
                                            color: "#fff",
                                        },
                                    }}>
                                    <QuickreplyIcon
                                        sx={{
                                            color: "#083344",
                                            fontSize: "1.5rem",
                                        }}
                                    />
                                </Badge>
                                <LikesBadge count={likes.length} />
                                <DislikesBadge count={disLikes.length} />
                            </AccordionSummary>
                            {replies.map((reply) => {
                                return (
                                    <AccordionDetails key={reply._id}>
                                        <div className="commet-reply-wrapper">
                                            <img
                                                alt={reply.author?.name}
                                                src={ImageUtils.getMixedObjLocation(reply.author)}
                                                className="w-[60px] h-[60px] rounded-sm ml-[10px] drop-shadow-md"
                                            />

                                            <div className="content">
                                                <div className="reply-info">
                                                    <div className="flex flex-row justify-start items-center">
                                                        <h6 className="ml-2">{reply.author?.name}</h6>
                                                        <LikesBadge count={reply.likes?.length} />
                                                        <DislikesBadge count={reply.disLikes?.length} />
                                                    </div>

                                                    <div className="date flex justify-between content-center items-center">
                                                        <EditDelete
                                                            user={user}
                                                            item={reply}
                                                            author={reply.author}
                                                            onEdit={() => onEditReply(comment, reply)}
                                                            onDelete={() => onDeleteReply(comment, reply)}
                                                        />
                                                        <LikeDislikeReport
                                                            user={user}
                                                            item={reply}
                                                            author={reply.author}
                                                            onLike={() => onLikeReply(comment, reply)}
                                                            onDislike={() => onDislikeReply(comment, reply)}
                                                            onReport={() => onReportReply(comment, reply)}
                                                        />
                                                        <Moment to={moment(reply.createdAt)} local="ar" />
                                                    </div>
                                                </div>
                                                <div className="reply-text">
                                                    {StringUtils.lineBreaks(reply.content)}
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                );
                            })}
                        </Accordion>
                    </>
                )}
            </div>
        </>
    );
};

export default BookCommentBar;
