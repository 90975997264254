import "../../assets/css/ribbon.css";
import { useTranslation } from "react-i18next";

const FreeBookBadge = ({ size = "small" }) => {
    const { t } = useTranslation();

    return (
        <div className={`ribbon ${size} ribbon-top-left`}>
            <span>{t("Free")}</span>
        </div>
    );
};

export default FreeBookBadge;
