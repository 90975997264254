class AppUtilis {
    static limitForTable(tableId) {
        // get limit for table from local storage
        const jsonLimit = localStorage.getItem("tableLimit");
        if (jsonLimit) {
            const limit = JSON.parse(jsonLimit);
            if (limit[tableId]) {
                return limit[tableId];
            }
        }
        return 25;
    }

    static setLimitForTable(tableId, limit) {
        // get limit for table from local storage
        const jsonLimit = localStorage.getItem("tableLimit");
        let limitObj = {};
        if (jsonLimit) {
            limitObj = JSON.parse(jsonLimit);
        }
        limitObj[tableId] = limit;
        localStorage.setItem("tableLimit", JSON.stringify(limitObj));
    }
}

export default AppUtilis;
