import { useTranslation } from "react-i18next";
import { Paper, TableRow, TableBody, TableCell, Typography } from "@mui/material";

const TableNoItemsFound = ({ filterName }) => {
  const { t } = useTranslation();
  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
          <Paper
            sx={{
              textAlign: "center",
            }}
          >
            <Typography variant="h6" paragraph>
              {t("Not found")}
            </Typography>

            {filterName && (
              <Typography variant="body2">
                {t("No results found for")} &nbsp;
                <strong>&quot;{filterName}&quot;</strong>.
                <br /> {t("Try checking for typos or using complete words.")}
              </Typography>
            )}
          </Paper>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default TableNoItemsFound;
