// @mui
import { styled } from "@mui/material/styles";
import { ListItemIcon, ListItemButton } from "@mui/material";

// ----------------------------------------------------------------------

export const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
    ...theme.typography.body2,
    height: 35,
    position: "relative",
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
}));

export const StyledNavItemIcon = styled(ListItemIcon)({
    width: 25,
    height: 25,
    minWidth: "auto",
    color: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});
