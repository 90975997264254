import React, { Suspense, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import Loading from "./components/Loadings/Loading";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "moment/locale/ar";
import "./index.css";
import "./app/i18n";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <StrictMode>
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <App />
      </Provider>
    </Suspense>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
