import { axios, requests } from "../../index";

const adminBooksLoader = async ({ params }) => {
    const args = params && params.type && params.type ? { type: params.type, id: params.id } : {};
    console.log("adminBooksLoader args", args);

    const { data } = await axios.get(`${requests.admin.books}`, { params: args });
    if (data && data.books) {
        return data.books;
    }
    return null;
};

export default adminBooksLoader;
