import PropTypes from "prop-types";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, List, ListItemText, Divider } from "@mui/material";
import { StyledNavItem, StyledNavItemIcon } from "./styles";
// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => {
          if (item.devider === true) {
            return <Divider key={item.title} />;
          } else if (item.title !== "user.switch") {
            return <NavItem key={item.title} item={item} />;
          }
          return null;
        })}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, tns } = item;

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isActive = pathname === path;

  return (
    <StyledNavItem component={RouterLink} to={path} selected={isActive}>
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText
        disableTypography
        primary={tns ? t(title, { ns: tns }) : t(title)}
        sx={{
          textAlign: "right",
        }}
      />

      {info && info}
    </StyledNavItem>
  );
}
