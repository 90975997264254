import { Suspense } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectUser } from "./features/userSlice";

const AdminRoute = ({ children }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const location = useLocation();

  const superAdmins = ["engr.amr.yousry@gmail.com", "ibo2001@gmail.com"];

  const bailTo = (page, message, toastId) => {
    return (
      <Navigate
        to={page}
        replace
        state={{
          toastType: "error",
          toastId: toastId,
          toastMessage: message,
        }}
      />
    );
  };

  if (!user) {
    return bailTo("/login", t("alerts.notAuthorized"), "notAuthenticated");
  }

  // if user.email is not in superAdmins, bail to /dashboard
  if (!superAdmins.includes(user.email)) {
    if (user && !user.isAdmin) {
      return bailTo("/", t("alerts.notAuthorized"), "notAdmin");
    }
    if (
      location.pathname.slice(6).split("/").length > 1 &&
      !user.caps.includes("/" + location.pathname.slice(6).split("/")[1])
    ) {
      return bailTo("/", t("alerts.notAuthorized"), "adminNotAuthorized");
    }
  }
  return <Suspense fallback={<>...</>}>{children}</Suspense>;
};

export default AdminRoute;
