import { useState } from "react";
import { Outlet } from "react-router-dom";
import { styled, Box } from "@mui/material";
import UserSideBar from "../components/userSidebar";
import { SubscripePrompt } from "../components/SubscripePrompt";
import userEndPoints from "../app/userEndPoints";
// ------------------------------------------------------------------//
// ------------------------------------------------------------------//
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;
// ------------------------------------------------------------------//
// ------------------------------------------------------------------//
const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});
// ------------------------------------------------------------------//
// ------------------------------------------------------------------//
const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
// ------------------------------------------------------------------//
// ------------------------------------------------------------------//
export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  // ------------------------------------------------------------------//
  // ------------------------------------------------------------------//
  return (
    <StyledRoot>
      <UserSideBar
        openNav={open}
        onCloseNav={() => setOpen(false)}
        endPoints={userEndPoints}
        bottomChildren={
          <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
            <SubscripePrompt
              listClass="text-[0.7rem]"
              path="account/change-plan"
            />
          </Box>
        }
        sidebarHeader={true}
      />
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
