import { styled } from "@mui/material/styles";
import { CardHeader } from "@mui/material";

const DenseCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: theme.spacing(0),
  backgroundColor: theme.palette.slate.lighter,
  "& .MuiCardHeader-avatar": {
    margin: theme.spacing(0),
  },
  "& .MuiCardHeader-subheader": {
    direction: "ltr",
  },
  "& .MuiCardHeader-action": {
    margin: theme.spacing(0),
  },
}));

export default DenseCardHeader;
