class Cart {
    constructor(cart) {
        this.id = cart ? cart.id : `cart_${Date.now()}`;
        this.items = cart ? cart.items : [];
        this.transRef = cart ? cart.transRef : null;
    }
    save() {
        localStorage.setItem("cart", JSON.stringify(this));
    }
    static get() {
        const cart = JSON.parse(localStorage.getItem("cart"));
        return cart ? new Cart(cart) : new Cart();
    }
    addItem(item) {
        this.items = [];
        this.items.push(item);
        this.save();
    }
    removeItem(item) {
        this.items = this.items.filter((i) => i._id !== item._id);
        this.save();
    }
    clear() {
        this.items = [];
        this.save();
    }
    // set transRef
    setTransRef(transRef) {
        console.log("setTransRef", transRef);
        this.transRef = transRef;
        this.save();
    }
    destroy() {
        localStorage.removeItem("cart");
    }

    get total() {
        return this.items.reduce((total, item) => total + item.price, 0);
    }
    get totalItems() {
        return this.items.length;
    }
    get isEmpty() {
        return this.items.length === 0;
    }
}

export default Cart;
