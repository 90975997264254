import FreeBookBadge from "./FreeBookBadge";

const BookRowThumbnail = ({ isCats, itemImage, itemTitle, isFree = false }) => {
    if (isCats) {
        return <img src={itemImage} className="cursor-pointer object-none h-36 w-36 mx-auto" alt={itemTitle} />;
    }

    return (
        <div className="relative">
            <img
                src={itemImage}
                className="cursor-pointer object-cover w-full h-40 border-b border-stone-950"
                alt={itemTitle}
            />
            {isFree && <FreeBookBadge size="small" />}
        </div>
    );
};

export default BookRowThumbnail;
