import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Avatar, ListItemAvatar, Button, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { ImageUtils } from "../utils";

export default function Dropdown({
    items,
    currentItem,
    labelKey,
    subItemsCountKey,
    imageKey,
    path,
    listAvatar = true,
}) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const currentItemCubItemsCount = currentItem ? currentItem[subItemsCountKey] : null;
    const currentItemTranslatedCubItemsCount = currentItemCubItemsCount
        ? t("books_count", { count: currentItemCubItemsCount })
        : "";

    const handleClose = (item) => {
        setAnchorEl(null);
        if (item._id) {
            navigate(`/${path}/${item._id}/books`);
        }
    };

    return (
        <div>
            <Button
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                }}
                color="dark"
                startIcon={<ArrowDropDownIcon />}>
                {currentItem ? `${currentItem[labelKey]} - ${currentItemTranslatedCubItemsCount} - ` : t("categories")}
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {items.map((item) => {
                    const subItemsCount = item[subItemsCountKey];
                    const translatedCubItemsCount = t("books_count", { count: subItemsCount });

                    return (
                        <MenuItem
                            key={item._id}
                            onClick={() => handleClose(item)}
                            selected={currentItem && item._id === currentItem._id}>
                            <div className="flex content-center items-center">
                                {listAvatar === true ? (
                                    <ListItemAvatar>
                                        <Avatar
                                            alt={item[labelKey]}
                                            src={ImageUtils.getMixedObjLocation(item[imageKey])}
                                        />
                                    </ListItemAvatar>
                                ) : (
                                    <Avatar sx={{ bgcolor: "#f1f1f1" }}>
                                        <img
                                            alt={item[labelKey]}
                                            src={ImageUtils.getMixedObjLocation(item[imageKey])}
                                            className="object-scale-down w-full h-full p-2"
                                        />
                                    </Avatar>
                                )}

                                <div className="ms-2">
                                    <p className="mb-0">
                                        {item[labelKey]}{" "}
                                        <span className="text-gray-400 text-xs mr-1">({translatedCubItemsCount})</span>
                                    </p>
                                </div>
                            </div>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}
