import Checkbox from "@mui/material/Checkbox";
import { TextField, Autocomplete } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormMultiSelect = ({ label, options, propKey, multiple, selected, name, ...props }) => {
    return (
        <Autocomplete
            {...props}
            multiple={multiple}
            options={options}
            value={selected}
            disableCloseOnSelect
            getOptionLabel={(option) => option[propKey]}
            isOptionEqualToValue={(option, value) => {
                return option?._id === value?._id;
            }}
            name={name}
            ChipProps={{ size: "small" }}
            renderOption={(p, option, { selected }) => (
                <li {...p}>
                    <Checkbox
                        name={name}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option[propKey]}
                </li>
            )}
            renderInput={(params) => <TextField {...params} name={name} label={label} />}
        />
    );
};

export default FormMultiSelect;
