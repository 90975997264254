import { axios, requests } from "../../index";

const seriesesLoader = async () => {
    const { data } = await axios.get(requests.serieses);
    if (data && data.serieses) {
        return data.serieses;
    }
    return [];
};

export default seriesesLoader;
