import "./ContextMenu.css";

const ContextMenu = ({ rightClickItem, positionX, positionY, isShow, buttons, ContextMenuRef }) => {
  return (
    <menu
      ref={ContextMenuRef}
      style={{
        top: `${positionY + 2}px`,
        left: `${positionX + 2}px`,
      }}
      className={`context-menu ${isShow ? "active" : ""}`}>
      {buttons.map((button, index) => {
        function handleClick(e) {
          e.stopPropagation();
          button.onClick(e, rightClickItem);
        }
        if (button.isSpacer) return <hr key={index} />;
        return (
          <button onClick={handleClick} key={index} className="context-menu-button">
            <span>{button.text}</span>
            <span className="icon">{button.icon}</span>
          </button>
        );
      })}
    </menu>
  );
};

export default ContextMenu;
