import { Badge } from "@mui/material";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";

const DislikesBadge = ({ count = 0 }) => {
    if (count <= 0) return null;

    return (
        <Badge
            badgeContent={count}
            sx={{
                marginRight: "10px",
                "& .MuiBadge-badge": {
                    right: 0,
                    top: 13,
                    border: `2px solid ${"white"}`,
                    padding: "0 4px",
                    backgroundColor: "#c2410c",
                    color: "#fff",
                },
            }}>
            <HeartBrokenIcon
                sx={{
                    color: "#9a3412",
                    fontSize: "1.5rem",
                }}
            />
        </Badge>
    );
};

export default DislikesBadge;
