import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";

import axios from "../../api/axios";
import requests from "../../api/Requests";

import { ImageUtils } from "../../utils";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

function Banner() {
  const [announcements, setAnnouncements] = useState([]);
  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = (newDirection) => {
    const newPage = page + newDirection;
    if (newPage < 0) {
      setPage([announcements.length - 1, -1]);
    } else if (newPage >= announcements.length) {
      setPage([0, 1]);
    } else {
      setPage([newPage, newDirection]);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.get(requests.announcements);
        if (data && data.announcements) {
          setAnnouncements(data.announcements);
        }
        return data;
      } catch (error) {
        console.log("Banner fetchData Error: ", error);
      }
    }
    fetchData();
    return () => {
      // cleanup
      setPage([0, 0]);
    };
  }, []);

  // bail if no announcements
  if (!announcements.length) {
    return null;
  }

  // get announcement depending on page
  const announcement = announcements.find((_, index) => index === page);
  if (!announcement) {
    return null;
  }

  return (
    <header className="announcements max-w-screen-xl mx-auto mb-[1rem]">
      <div className="announcements__container">
        <AnimatePresence initial={false} custom={direction}>
          <motion.img
            key={page}
            src={ImageUtils.getMixedObjLocation(announcement.image)}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          />
        </AnimatePresence>
        <div className="next" onClick={() => paginate(1)}>
          <SkipNextIcon fontSize="1rem" />
        </div>
        <div className="prev" onClick={() => paginate(-1)}>
          <SkipPreviousIcon fontSize="1rem" />
        </div>
      </div>
    </header>
  );
}
export default Banner;
